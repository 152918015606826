import { useEffect, useState } from 'react';

import { AxiosError } from 'axios';

import {
  addError,
  removeErrors,
} from '@/app/app-providers/error-handler-provider/errorHandlerSlice';
import { errors } from '@/app/helpers/errors';
import {
  TCurrentTemplate,
  TemplateMap,
} from '@/app/modules/template-selector/templateSlice';
import { captureMessage } from '@/app/helpers/logging/logging';
import { useAppDispatch } from '@/app/app-redux/store';
import { useProcessAudio } from './useProcessAudio';
import { useCreateMedicalNote } from './useCreateMedicalNote';

import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from '@/app/helpers/services';
import { openNote } from '../../../full-note/noteSlice';

export enum ProcessingLabel {
  'processing_audio' = 'Recebendo seu áudio',
  'creating_note' = 'Emitindo nota',
}

type TUseConsultationAsyncProcess = {
  template: TCurrentTemplate;
  clearBlobUrl: () => void;
  audioBlob: Blob | null;
};

export const useConsultationAsyncProcess = ({
  audioBlob,
  template,
  clearBlobUrl,
}: TUseConsultationAsyncProcess) => {
  const dispatch = useAppDispatch();

  const [processingLabel, setProcessingLabel] =
    useState<ProcessingLabel | null>(null);

  const [isProcessing, setIsProcessing] = useState(false);

  const { processAudio } = useProcessAudio(audioBlob);

  const { createMedicalNote } = useCreateMedicalNote();

  const dispatchRegenerateError = (regeneratedLabels: TRegeneratedLabels) => {
    dispatch(
      addError({
        errors: [
          {
            ...errors['audio-001'],
            message: `sua nota "${regeneratedLabels.from}" está sendo alterada para o modelo "${regeneratedLabels.to}" devido a gravação ser menor de 30 segundos`,
          },
        ],
      })
    );
  };

  const getTemplateSuggestion = useMutation({
    retry: false,
    mutationFn: ({ transcriptionId }: { transcriptionId: string }) =>
      axiosInstance.get<string[]>('v2/medical_notes/recommended_templates', {
        params: {
          transcription_id: transcriptionId,
        },
      }),
  });

  useEffect(() => {
    (async () => {
      let consultationIdReprocess = '';
      let transcriptionIdReprocess = '';

      try {
        const startedAt = new Date().toISOString();
        setIsProcessing(true);
        setProcessingLabel(ProcessingLabel.processing_audio);
        const { consultationId, transcriptionId } = await processAudio();
        consultationIdReprocess = consultationId;
        transcriptionIdReprocess = transcriptionId;

        setProcessingLabel(ProcessingLabel.creating_note);
        const { medicalNoteId, noteTemplate } = await createMedicalNote({
          consultationId,
          transcriptionId,
          template,
          startedAt,
        });
        dispatch(
          openNote({ noteId: medicalNoteId, noteTemplate, isOpen: true })
        );
        clearBlobUrl();
      } catch (error) {
        if (error instanceof AxiosError) {
          const status = error.response?.status;
          if (status !== 422) {
            dispatch(addError({ errors: [errors.generic] }));
            captureMessage(error.message);
            return;
          }

          try {
            let newTemplate: TCurrentTemplate;

            try {
              const newTemplateResponse =
                await getTemplateSuggestion.mutateAsync({
                  transcriptionId: transcriptionIdReprocess,
                });
              newTemplate = newTemplateResponse.data[0] as TCurrentTemplate;
            } catch (_) {
              newTemplate = 'consultation_report';
            }

            const regeneratedLabels = {
              from: TemplateMap[template],
              to: TemplateMap[newTemplate],
            };
            dispatchRegenerateError(regeneratedLabels);

            const {
              medicalNoteId: reprocessedMedicalNoteId,
              noteTemplate: reprocessedNoteTemplate,
            } = await createMedicalNote({
              consultationId: consultationIdReprocess,
              transcriptionId: transcriptionIdReprocess,
              template: newTemplate,
              isReprocess: true,
            });

            if (reprocessedMedicalNoteId) {
              dispatch(
                openNote({
                  noteId: reprocessedMedicalNoteId || '',
                  isOpen: true,
                  regenerated: regeneratedLabels,
                  noteTemplate: reprocessedNoteTemplate,
                })
              );
              dispatch(removeErrors());
            }
          } catch (reprocessError) {
            dispatch(addError({ errors: [errors.generic] }));
            captureMessage('Error on reprocess audio', { level: 'warning' });
          }
        }
      } finally {
        setIsProcessing(false);
        setProcessingLabel(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBlob]);

  return {
    isProcessing,
    processingLabel,
  };
};

type TRegeneratedLabels = {
  from: string;
  to: string;
};
