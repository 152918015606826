import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type TMenuMobileButtonProps = {
  onClick: () => void;
};
export const OpenMenuMobileButton = ({ onClick }: TMenuMobileButtonProps) => (
  <Box
    className="side-menu-button"
    sx={{
      display: {
        xs: 'block',
        md: 'none',
      },
      alignItems: 'start',
      position: 'absolute',
      zIndex: 2,
      top: { xs: '25px', sm: '45px' },
      left: '8px',
    }}
  >
    <IconButton onClick={onClick}>
      <MenuIcon />
    </IconButton>
  </Box>
);

export const CloseMenuMobileButton = ({ onClick }: TMenuMobileButtonProps) => (
  <Box
    sx={{
      display: 'flex',
      opacity: {
        xs: 1,
        sm: 0,
      },
      width: '100%',
      justifyContent: 'end',
    }}
  >
    <IconButton onClick={onClick}>
      <ChevronLeftIcon />
    </IconButton>
  </Box>
);
