import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { root } from '@/app/routes';
import logo from '../../../../assets/logo.png';

export const HeaderLogo = () => (
  <Box component={Link} to={root}>
    <img src={logo} alt="logo" width="206px" />
  </Box>
);
