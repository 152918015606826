import { createTheme } from '@mui/material';

const originalTheme = createTheme();

export const themeObject = {
  palette: {
    mode: 'light',
    background: { default: '#f5f3f1' },
    primary: {
      main: '#FF5D00',
      light: '#fff2e8',
      dark: '#FF5D00',
      contrastText: '#e9e8ff',
    },
    secondary: {
      main: '#543250',
      light: '#dcdcdc',
      dark: '#543250',
      contrastText: '#f3f3f3',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:first-letter': {
            textTransform: 'uppercase', // Capitalize the first letter
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&&': {
            padding: '9px 12px', // && it's a hack to increase specificity
          },
        },
      },
    },
  },
  typography: {
    allVariants: {},
    h1: {
      fontSize: '5.0rem',
      fontWeight: '700',
      fontFamily: 'Atkinson Hyperlegible',
      lineHeight: '5.3rem',
    },
    h6: {
      fontSize: '1.1rem',
      lineHeight: '1.2rem',
    },
    button: {
      fontSize: '1.7rem',
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: '2.5em',
      fontWeight: 400,
      lineHeight: '100%',
      [originalTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '1.875em',
      },
    },
    subtitle2: {
      fontSize: '1.375em',
      [originalTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '1.275em',
      },
    },
    paragraph: {
      fontSize: '1.125em',
      lineHeight: '1.5em',
    },
    caption: {
      letterSpacing: 'inherit',
    },
  },
};
