import { addError } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';
import { AppDispatch } from '@/app/app-redux/store';
import { FIREBASE_AUTH_ERROR_MESSAGES, errors } from '@/app/helpers/errors';
import { FirebaseError } from 'firebase/app';

export const dispatchFirebaseError =
  (dispatch: AppDispatch) => (error: unknown) => {
    if (error instanceof FirebaseError) {
      const errorCode = error.code;
      dispatch(
        addError({
          errors: [
            {
              title: errors.generic.title,
              message:
                FIREBASE_AUTH_ERROR_MESSAGES[
                  errorCode as keyof typeof FIREBASE_AUTH_ERROR_MESSAGES
                ] || errors.generic.message + ' - ' + errorCode,
            },
          ],
        })
      );
    } else {
      dispatch(
        addError({
          errors: [
            {
              title: errors.generic.title,
              message: errors.generic.message,
            },
          ],
        })
      );
    }
  };
