import {
  ConsultationMode,
  TemplateMap,
} from '@/app/modules/template-selector/templateSlice';
import { TMultiSelectOptions } from './components/multiline-select/multiline-select';

export const registerTypeOptions: TMultiSelectOptions[] = [
  {
    label: TemplateMap.general_practitioner,
    value: 'general_practitioner',
    subItems: [
      {
        label:
          'História da moléstia atual, antecedentes pessoais, alergias, histórico familiar, impressões, conduta, observações',
      },
      {
        label: 'Gravações acima do tempo de 1 minuto',
      },
    ],
  },
  {
    label: TemplateMap.cardiologist,
    value: 'cardiologist',
    subItems: [
      {
        label:
          'Antecedentes pessoais, história da moléstia atual, dados antropométricos, impressão ou hipótese diagnóstica, conduta, observações',
      },
      {
        label: 'Gravações acima do tempo de 1 minuto',
      },
    ],
  },
  {
    label: TemplateMap.soap,
    value: 'soap',
    subItems: [
      {
        label: 'Subjetivo, objetivo, avaliação, plano',
      },
      {
        label: 'Gravações acima do tempo de 1 minuto',
      },
    ],
  },
  {
    label: TemplateMap.consultation_report,
    value: 'consultation_report',
    subItems: [
      {
        label: 'Transcrição individual de consulta domiciliar / hospitalar',
      },
      {
        label: 'Gravações acima do tempo de 0 minuto',
      },
    ],
  },
  {
    label: TemplateMap.plastic_surgeon,
    value: 'plastic_surgeon',
    subItems: [
      {
        label:
          'Queixa da paciente, histórico clínico, atividades físicas, histórico de medicamentos, alergias, histórico cirúrgico, exame físico, diagnóstico, planejamento cirúrgico, outros',
      },
      {
        label: 'Gravações acima do tempo de 1 minuto',
      },
    ],
  },
  {
    label: TemplateMap.orthopedist,
    value: 'orthopedist',
    subItems: [
      {
        label:
          'Queixa da paciente, histórico clínico, histórico de medicamentos , histórico cirurgico, exame físico, exames de imagem, conduta médica, outros',
      },
      {
        label: 'Gravações acima do tempo de 1 minuto',
      },
    ],
  },
  {
    label: TemplateMap.physiotherapist,
    value: 'physiotherapist',
    subItems: [
      {
        label:
          'Queixa da paciente, Histórico clínico, Qualidade de vida e hábitos, Histórico de atividade física, Histórico de medicamentos , Histórico cirúrgico, Exame físico, Exames de imagem, Conduta da Fisioteurapeuta, Diagnóstico disfuncional, Evolução das sessões, Prescrições e orientações, outros',
      },
      {
        label: 'Gravações acima do tempo de 1 minuto',
      },
    ],
  },
  {
    label: TemplateMap.tasks,
    value: 'tasks',
    subItems: [],
  },
  {
    label: 'Solicitar novo Tipo de registro',
    value: 'request_new_type',
    noIcon: true,
    subItems: [
      {
        label: 'Peça ao nosso time outro Tipo de registro',
      },
    ],
  },
];

export const consultationModeOptions: TMultiSelectOptions[] = [
  {
    label: ConsultationMode.in_person,
    value: 'in_person',
    noIcon: true,
    subItems: [],
  },
  {
    label: ConsultationMode.remote,
    value: 'remote',
    noIcon: true,
    subItems: [
      {
        label: 'Gravar sem fones de ouvido',
      },
    ],
  },
];
