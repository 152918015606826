import { MenuItem, styled } from '@mui/material';

export const MultilineMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  flex-direction: column;
  align-items: start;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.light};
  &:nth-of-type(2) {
    border-top: 1px solid ${({ theme }) => theme.palette.secondary.light};
  }
  &:last-child {
    border-bottom: none;
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    padding-left: ${({ theme }) => theme.spacing(2)};
    list-style: disc;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: '•';
      margin-right: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
