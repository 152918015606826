import { getRemoteConfig, getValue } from 'firebase/remote-config';

export const defaultFlags = {
  download_audio_enabled: false,
  logging: false,
  firebase_upload_audio_enabled: false,
  testing_users: [],
  exclude_note_enabled: false,
};

type TFlag = keyof typeof defaultFlags;
type TFlagType = 'boolean' | 'string' | 'number' | 'json'; // Firebase remote config types
export type TGetFlag = {
  name: TFlag;
  type: TFlagType;
};

export const getRemoteConfigFlag = (opts: TGetFlag) => {
  try {
    const remoteConfig = getRemoteConfig();
    const value = getValue(remoteConfig, opts.name);

    return {
      boolean: value.asBoolean(),
      string: value.asString(),
      number: value.asNumber(),
      json: value.asString(),
    }[opts.type];
  } catch (error) {
    console.log(error, 'Firebase getFlag');
    return defaultFlags[opts.name];
  }
};
