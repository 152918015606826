import { Provider } from 'react-redux';
import { store } from '../app-redux/store';
import { QueryClient } from '@tanstack/react-query';
import { Auth } from '../modules/auth/auth';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ErrorHandlerProvider } from './error-handler-provider';
import {
  CssBaseline,
  ThemeOptions,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { DialogModule } from '../modules/dialog-module/dialog-module';
import { themeObject } from '../theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24 * 360, // 1 year
      staleTime: 1, // 1 year
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

type TAppProviders = {
  children: React.ReactNode;
};

export const AppProviders = ({ children }: TAppProviders) => {
  const theme = createTheme(themeObject as ThemeOptions);

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <Provider store={store}>
        <ErrorHandlerProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DialogModule />
            <Auth>{children}</Auth>
          </ThemeProvider>
        </ErrorHandlerProvider>
      </Provider>
    </PersistQueryClientProvider>
  );
};
