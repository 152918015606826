import { Box, Typography, styled } from '@mui/material';
import whatsappLogo from '@/assets/whatsapp-logo.svg';
import { useIsMobile } from '../helpers/hooks/useIsMobile';
import { useLocation } from 'react-router-dom';
import * as routes from '../routes';

const whatsappBase = 'https://wa.me/551151942680';

export const getWhatsappLink = (message: string) =>
  `${whatsappBase}?text=${message}`;

const Wrapper = styled(Box)`
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-decoration: none;
  text-align: center;
` as typeof Box;

export const FixedWhatsappButton = () => {
  const isMobile = useIsMobile();
  const location = useLocation();

  const textMapper = {
    [routes.root]: 'Gostaria de falar com o time sobre a gravação',
    [routes.notes]: 'Quero ajuda com minhas notas anteriores',
    [routes.help]: 'Quero ajuda para usar a carecode',
  };

  const whatsappLink = `${whatsappBase}?text=${
    textMapper[location.pathname as keyof typeof textMapper]
  }`;

  return (
    <Wrapper
      sx={{
        bottom: { xs: '164px', md: '64px' },
      }}
      component="a"
      href={whatsappLink}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Box>
        <img
          src={whatsappLogo}
          alt="Entre em contato por whatsapp"
          width={isMobile ? '55px' : '80px'}
        />
        <Box>
          <Typography variant={isMobile ? 'caption' : 'body1'}>
            Entre em contato
          </Typography>
        </Box>
      </Box>
    </Wrapper>
  );
};
