import { useQuery } from '@tanstack/react-query';
import { TGetFlag, getRemoteConfigFlag } from './remote-config';

export const useRemoteConfig = (remoteKey: TGetFlag) => {
  const remoteConfigQuery = useQuery({
    queryKey: ['remoteConfig', remoteKey.name],
    queryFn: async () => {
      const value = getRemoteConfigFlag(remoteKey);

      return value;
    },
  });

  return remoteConfigQuery;
};
