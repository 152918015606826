import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

export const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box maxWidth={'680px'} sx={{ paddingBottom: { xs: '100%', md: '10%' } }}>
      <Typography variant="subtitle2">
        POLÍTICA DE PRIVACIDADE – COPILOTO DE SAÚDE
      </Typography>
      <Box my="32px">
        <Box mb="16px">
          <Typography>Em vigor a partir de 25/3/2024</Typography>
        </Box>
        <Box mb="16px">
          <Typography>
            A CARELABS LTDA., inscrita no CNPJ/MF sob o nº 47.705.201/0001-58
            ("CARECODE"), disponibiliza uma ferramenta tecnológica que auxilia o
            médico na execução de suas atividades profissionais de produção e
            preenchimento de prontuário médico do paciente atendido, a partir da
            gravação em áudio da consulta médica (“Copiloto de Saúde” ou
            “Ferramenta”).
          </Typography>
        </Box>
        <Box mb="16px">
          <Typography>
            A CARECODE tem o compromisso de respeitar a sua privacidade e preza
            pela segurança de seus dados pessoais. Por esse motivo, criamos esta
            Política de Privacidade (“Política”) para explicar como tratamos as
            informações dos usuários que: (i) contratam e se cadastram no
            Copiloto de Saúde (“Clientes CARECODE”); e (ii) participam das
            gravações de áudio realizadas pelo Copiloto de Saúde (“Usuário
            Gravado”).
          </Typography>
        </Box>
        <Box mb="16px">
          <Typography>
            POR FAVOR, RECOMENDAMOS QUE A POLÍTICA ABAIXO SEJA LIDA COM ATENÇÃO.
            ELA DESCREVE COMO OS DADOS PESSOAIS SÃO TRATADOS PELA CARECODE E
            ESCLARECE QUANDO A CARECODE ATUA COMO CONTROLADORA DE DADOS E QUANDO
            A CARECODE ATUA COMO OPERADORA DE DADOS.
          </Typography>
        </Box>
        <Box mb="16px">
          <Typography fontWeight="bold">
            Note que não é possível oferecer as funcionalidades da Ferramenta
            sem o tratamento dos dados pessoais descrito nesta Política. Ou
            seja, o tratamento dos dados pessoais é condição para utilizar a
            Ferramenta.
          </Typography>
        </Box>
        <Box mb="16px">
          <Typography>
            Não coletamos mais dados pessoais do que os estritamente necessários
            para a execução de nossas atividades. Caso você não esteja de acordo
            com as disposições desta Política, você deverá descontinuar o uso
            dos nossos serviços e/ou o seu acesso ou uso de nossa Ferramenta.
          </Typography>
        </Box>
        <Box mb="8px" sx={{ li: { marginBottom: '16px' } }}>
          <ol>
            <li>
              <Typography mb="8px">Informações Gerais</Typography>

              <Typography mb="8px">
                A CARECODE atua como controladora dos dados pessoais de Clientes
                CARECODE, que contratam, se cadastram e utilizam o Copiloto de
                Saúde.
              </Typography>
              <Typography mb="8px">
                Por outro lado, a CARECODE atuará como operadora dos dados
                pessoais dos Usuários Gravados, obtidos durante a consulta
                médica e registrados nas gravações e prontuários eletrônicos. Os
                Clientes CARECODE são os controladores desses dados pessoais.
              </Typography>
              <Typography mb="8px">
                Os Clientes CARECODE, como controladores, são responsáveis (i)
                pelas informações coletadas durante as consultas médicas e
                inseridas nos prontuários médicos; e (ii) pelas informações
                prestadas aos Usuários Gravados sobre as finalidades do
                tratamento relacionado ao Copiloto de Saúde.
              </Typography>
              <Typography mb="8px">
                Vale lembrar que o uso do Copiloto de Saúde para a gravação do
                áudio da consulta deve ocorrer mediante a coleta do
                consentimento livre e esclarecido do Usuário Gravado. A coleta
                do consentimento é de responsabilidade exclusiva do Cliente
                CARECODE, que atua na qualidade de controlador dos dados
                pessoais dos Usuários Gravados.
              </Typography>
              <Typography mb="8px">
                Caso você tenha quaisquer dúvidas ou pedidos de esclarecimentos
                sobre a utilização dos seus dados pessoais, você também pode
                contatar a CARECODE através do nosso Encarregado de Proteção de
                Dados (DPO) no e-mail: dpo@carecode.com.br.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">
                Dados Pessoais coletados e tratados
              </Typography>

              <Typography mb="8px">Fontes dos dados pessoais.</Typography>
              <Typography mb="8px">
                A CARECODE somente coleta ou recebe dados pessoais quando:
              </Typography>
              <ol type="i">
                <li>
                  O Cliente CARECODE nos fornece a informação diretamente,
                  através de cadastro para contratação e uso do Copiloto de
                  Saúde; e
                </li>
                <li>
                  O Cliente CARECODE utiliza o Copiloto de Saúde para a gravação
                  de uma consulta e posterior geração do prontuário médico.
                </li>
              </ol>

              <Typography mb="8px">Dados de Clientes CARECODE.</Typography>
              <Typography mb="8px">
                Os Clientes CARECODE podem ser: (a) médicos individuais (pessoa
                física); (b) médicos através de suas pessoas jurídicas MEI –
                Microempreendedor Individual; (c) pessoas jurídicas de outras
                naturezas, como sociedade limitada; e (d) hospitais, clínicas,
                consultórios, que são pessoais jurídicas e envolvem a prestação
                de serviços médicos.
              </Typography>
              <Typography mb="8px">
                Para a realização de cadastro e utilização do Copiloto de Saúde,
                a CARECODE poderá tratar dados pessoais de médicos (pessoas
                físicas ou microempreendedores individuais) ou de representantes
                legais de pessoas jurídicas.
              </Typography>
              <Typography mb="8px">
                Os dados eventualmente tratados pela CARECODE incluem:
              </Typography>
              <Box mb="8px">
                <ul>
                  <li>Nome completo;</li>
                  <li>Endereço de e-mail;</li>
                  <li>Telefone celular;</li>
                </ul>
              </Box>
              <Typography mb="8px">Dados de Usuários Gravados.</Typography>
              <Typography mb="8px">
                A CARECODE poderá tratar, em nome e sob as instruções do Cliente
                CARECODE, como operada de dados pessoais, os seguintes dados
                pessoais, coletados durante a consulta médica e registrados nas
                gravações e no prontuário médico:
              </Typography>
              <Box mb="8px">
                <ul>
                  <li>Nome completo;</li>
                  <li>Endereço de e-mail;</li>
                  <li>Telefone celular;</li>
                  <li>CPF;</li>
                  <li>RG;</li>
                  <li>Número do documento do plano de saúde;</li>
                  <li>Dados desaúde;</li>
                  <li>Histórico familiar;</li>
                  <li>
                    Dados biométricos de voz, captados durante a gravação do
                    áudio da consulta.
                  </li>
                  <li>
                    Quaisquer outras informações fornecidas pelo Usuário Gravado
                    durante a consulta
                  </li>
                </ul>
              </Box>
              <Typography mb="8px">
                Dados de menores de 18 (dezoito) anos.
              </Typography>
              <Typography mb="8px">
                Podemos ter acesso a dados pessoais de menores de 18 anos, na
                qualidade de operadores de dados, caso os Usuários Gravados
                sejam menores de 18 anos.
              </Typography>
              <Typography mb="8px">
                Ressaltamos a responsabilidade do Cliente CARECODE de informar
                aos Usuários Gravados – e seus representantes legais, sempre que
                aplicável – sobre o tratamento de dados, bem como de coletar os
                consentimentos necessários.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">Finalidades de tratamento</Typography>
              <Typography mb="8px">Dados de Clientes CARECODE.</Typography>
              <Typography mb="8px">
                Nós tratamos os dados pessoais de Clientes CARECODE para as
                seguintes finalidades:
              </Typography>
              <Box mb="8px">
                <ol type="i">
                  <li>Identificar e autenticar usuários da Ferramenta;</li>
                  <li>
                    Cadastrar os usuários em nossos sistemas para
                    disponibilização da Ferramenta;
                  </li>
                  <li>
                    Entrar em contato com os usuários (por exemplo, através do
                    e-mail cadastrado) para prestar informações sobre diversos
                    assuntos, como por exemplo relacionados ao uso da
                    Ferramenta;
                  </li>
                  <li>
                    Prestar suporte na utilização e funcionalidades da
                    Ferramenta;
                  </li>
                  <li>Realizar pesquisas de satisfação;</li>
                  <li>
                    Enviar mensagens com informações sobre produtos ou serviços;
                  </li>
                  <li>
                    Realizar publicidade e marketing, com a oferta de produtos e
                    serviços;
                  </li>
                  <li>
                    Aprimorar a relação por meio de análise e estudos
                    estatísticos;
                  </li>
                  <li>Cumprir com obrigações legais;</li>
                  <li>Exercer os direitos da CARECODE;</li>
                  <li>
                    Garantir a segurança e integridade dos nossos serviços;
                  </li>
                  <li>Desenvolver e aprimorar nossos produtos e serviços.</li>
                </ol>
              </Box>
              <Typography mb="8px">Dados de Usuários Gravados.</Typography>
              <Typography mb="8px">
                Nós tratamos os dados pessoais de Usuários Gravados apenas de
                acordo com as instruções recebidas dos Clientes CARECODE para
                realizar a transcrição da consulta e posterior auxílio na
                elaboração do prontuário médico, de forma a mantê-lo o mais
                completo e atualizado possível.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">Retenção de dados pessoais</Typography>
              <Typography mb="8px">Dados de Clientes CARECODE.</Typography>
              <Typography mb="8px">
                Nós estruturamos a nossa Ferramenta e os nossos sistemas para
                que os seus dados pessoais não sejam mantidos por mais tempo do
                que o necessário. Ou seja, nós mantemos os dados pessoais
                somente durante o período em que eles são necessários (i) para
                as finalidades descritas acima; (ii) para o cumprimento de
                obrigações legais ou regulatórias às quais a CARECODE esteja
                sujeita; (iii) para exercício regular de nossos direitos; ou
                (iv) para realizar o tratamento de dados pessoais justificado em
                bases legais permitidas pela LGPD.
              </Typography>
              <Typography mb="8px">
                Podemos manter os dados pessoais de maneira anonimizada, ou
                seja, sem que tais dados possam ser relacionados a você, por
                períodos maiores.
              </Typography>
              <Typography mb="8px">Dados de Usuários Gravados.</Typography>
              <Typography mb="8px">
                Mantemos o áudio da consulta armazenado pelo período de 15 dias.
                Após esse período, os mesmos são completamente apagados. Os
                dados de transcrição da consulta e a nota médica são armazenados
                indefinidamente, podendo o controlador excluí-los a qualquer
                momento. Todos os dados armazenados são criptografados.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">
                Compartilhamento de dados pessoais
              </Typography>
              <Typography mb="8px">
                Não vendemos nem comercializamos os seus dados pessoais.
                Entretanto, podemos eventualmente compartilhar os dados pessoais
                com:
              </Typography>
              <Box mb="8px">
                <ol type="i">
                  <li>
                    <Typography mb="8px">
                      Holdings, afiliadas, subsidiárias e empresas do mesmo
                      grupo econômico, para auxiliar na gestão e administração
                      da Ferramenta e desempenho das estatísticas de utilização
                      dos nossos serviços;
                    </Typography>
                  </li>
                  <li>
                    <Typography mb="8px">
                      Prestadores de serviço para a prestação de serviços
                      auxiliares, como, por exemplo, serviços de software e
                      infraestrutura em nuvem e serviços de envio de
                      comunicações eletrônicas;
                    </Typography>
                  </li>
                  <li>
                    <Typography mb="8px">
                      Ferramentas de inteligência artificial que nos auxiliam na
                      prestação dos serviços;
                    </Typography>
                  </li>
                  <li>
                    <Typography mb="8px">
                      Autoridades judiciais, administrativas ou governamentais
                      competentes, sempre que houver determinação legal,
                      requerimento, requisição ou ordem judicial, na medida do
                      previsto em lei;
                    </Typography>
                  </li>
                  <li>
                    <Typography mb="8px">
                      Potenciais compradores ou investidores, em caso de
                      movimentações societárias, como fusão, aquisição e
                      incorporação; e
                    </Typography>
                  </li>
                  <li>
                    <Typography mb="8px">
                      Empresas de auditoria tão somente para a realização de
                      auditorias nas operações da CARECODE e para cumprimento de
                      obrigações legais ou regulatórias.
                    </Typography>
                  </li>
                </ol>
              </Box>
              <Typography mb="8px">
                Os seus dados pessoais podem ser transferidos
                internacionalmente. Caso o país que receba os dados pessoais não
                possua nível adequado de proteção de dados pessoais conforme
                determinações da Autoridade Nacional de Proteção de Dados, a
                CARECODE determinará o mecanismo que será utilizado para
                garantir a legalidade da transferência internacional de dados
                pessoais, segundo as regras constantes na LGPD e normativos
                emitidos pela Autoridade Nacional de Proteção de Dados.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">
                Seus direitos relacionados aos seus dados pessoais
              </Typography>
              <Typography mb="8px">
                Em todos os casos legalmente cabíveis, os titulares de dados
                pessoais poderão exercer os seguintes direitos com relação aos
                seus dados pessoais:
              </Typography>
              <Box mb="8px">
                <ol type="i">
                  <li>
                    Confirmação de que realizamos o tratamento dos seus dados
                    pessoais;
                  </li>
                  <li>Acesso aos seus dados pessoais;</li>
                  <li>
                    Correção de dados pessoais que estejam incompletos, inexatos
                    ou desatualizados;
                  </li>
                  <li>
                    Anonimização, bloqueio ou eliminação de dados pessoais
                    desnecessários, excessivos ou tratados em desconformidade
                    com o disposto na LGPD;
                  </li>
                  <li>
                    Portabilidade dos seus dados pessoais a outro fornecedor de
                    serviço ou produto, observados os nossos segredos comerciais
                    e industriais, após a regulamentação dos meios pela
                    Autoridade Nacional de Proteção de Dados;
                  </li>
                  <li>
                    Eliminação dos dados pessoais tratados com base no seu
                    consentimento, exceto nas hipóteses de conservação de dados
                    pessoais previstas na LGPD;
                  </li>
                  <li>
                    Informação sobre com quem compartilhamos os seus dados
                    pessoais;
                  </li>
                  <li>
                    Informação sobre a possibilidade de não fornecer o seu
                    consentimento;
                  </li>
                  <li>
                    Revogação do seu consentimento para o tratamento dos seus
                    dados pessoais, quando os dados pessoais forem tratados com
                    base no seu consentimento;
                  </li>
                  <li>Oposição a tratamento que viole a LGPD; e</li>
                  <li>
                    Petição em relação aos seus dados pessoais perante a
                    Autoridade Nacional de Proteção de Dados.
                  </li>
                </ol>
              </Box>
              <Typography mb="8px">
                Titulares podem exercer quaisquer dos direitos acima enviando um
                e-mail para dpo@carecode.com.br.
              </Typography>
              <Typography mb="8px">
                A CARECODE responderá o seu pedido dentro dos prazos
                estabelecidos pelos regulamentos de proteção de dados aplicáveis
                ao tratamento dos seus dados pessoais.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">
                Armazenamento de seus dados pessoais
              </Typography>
              <Typography mb="8px">
                Nós armazenamos seus dados pessoais de forma segura em data
                centers de terceiros localizados no Brasil e nos Estados Unidos.
                Atualmente, contratamos os serviços de data centers fornecidos
                por operadores de serviços de computação em nuvem. Antes de
                enviar os seus dados para armazenamento em outros países, nós
                adotamos as medidas exigidas por lei para assegurar que eles
                continuem protegidos.
              </Typography>
              <Typography mb="8px">
                Nós adotamos as melhores práticas técnicas e administrativas
                para proteger os dados pessoais de acessos não autorizados,
                destruição, perda, alteração, comunicação ou qualquer forma de
                tratamento inadequado ou ilícito.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">Alterações a esta Política</Typography>
              <Typography mb="8px">
                A CARECODE poderá modificar, atualizar, estender ou de qualquer
                outra forma alterar o conteúdo e o escopo desta Política a
                qualquer momento e a seu exclusivo critério. Nesses casos,
                publicaremos essas alterações em nosso website com a indicação
                da última data de atualização (que você pode encontrar no início
                desta Política).
              </Typography>
              <Typography mb="8px">
                As alterações nesta Política também podem ser comunicadas por
                e-mail, desde que o referido meio tenha sido estabelecido como
                um canal de comunicação entre você e a CARECODE, durante a
                vigência de uma relação jurídica. O uso contínuo dos serviços da
                CARECODE após notificação via e-mail ou publicação no website
                sobre as mudanças em nossa Política consistirá em sua aceitação
                dos novos termos.
              </Typography>
              <Typography mb="8px">
                A CARECODE incentiva você a periodicamente revisitar esta
                Política para acompanhar nossas medidas de privacidade.
              </Typography>
            </li>
            <li>
              <Typography mb="8px">Contato</Typography>
              <Typography mb="8px" paddingBottom={'120px'}>
                Se você tiver alguma dúvida ou reclamação relacionada a esta
                Política, entre em contato conosco através do
                dpo@carecode.com.br.
              </Typography>
            </li>
          </ol>
        </Box>
      </Box>
    </Box>
  );
};
