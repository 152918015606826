import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/browser';

import { queryOptions, useQuery } from '@tanstack/react-query';
import {
  addAuthHeader,
  axiosInstance,
  getAuthId,
} from '../../helpers/services';
import { useEffect, useState } from 'react';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { useRemoteConfig } from '@/app/infra/firebase/useRemoteConfig';
import { useAppDispatch } from '@/app/app-redux/store';
import { addLoggedUser, addTestingUsers } from './authSlice';
import { Box, CircularProgress } from '@mui/material';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { LoginModal } from './components/login-modal/login-modal';
import { useIsTestingUser } from '@/app/helpers/hooks/useIsTestingUser';
import { SignupModal } from './components/signup-modal/signup-modal';
import { ForgotPassword } from './components/forgot-password';
import { identify } from '@amplitude/analytics-browser';

export const authDataQuery = queryOptions<{ data: { id: string } }>({
  queryKey: ['auth'],
  queryFn: async () => {
    const userIdFromLocalStorage = getAuthId();
    if (userIdFromLocalStorage) {
      return Promise.resolve({ data: { id: userIdFromLocalStorage } });
    }

    const response = await axiosInstance.post<TServiceSignUp>('/auth/signup');
    const currentId = response?.data.id;
    if (currentId) localStorage.setItem('user_id', currentId);
    return { data: { id: response?.data?.id } };
  },
  staleTime: Infinity,
  gcTime: Infinity,
});

type TAuth = {
  children: React.ReactNode;
};
export const Auth = ({ children }: TAuth) => {
  const firebaseAuth = getAuth();
  const dispatch = useAppDispatch();
  const [isAuthReady, setIsAuthReady] = useState(false);

  const isTestingUser = useIsTestingUser();

  const { data: testingUsers } = useRemoteConfig({
    name: 'testing_users',
    type: 'json',
  });

  const { data: authData } = useQuery({
    ...authDataQuery,
  });

  const { data: userData } = useQuery({
    queryKey: ['user', authData?.data.id],
    queryFn: () =>
      axiosInstance.post<TServiceSignIn>('/auth/signin', {
        id: authData?.data?.id,
      }),
    enabled: !!authData?.data?.id,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  useEffect(() => {
    amplitude.setUserId(authData?.data.id);
    Sentry.setUser({ id: authData?.data.id });
    const analytics = getAnalytics();
    setUserId(analytics, authData?.data.id || '');
  }, [authData?.data.id]);

  useEffect(() => {
    if (typeof testingUsers === 'string') {
      try {
        const parsedTestingUsers = JSON.parse(testingUsers);
        dispatch(addTestingUsers(parsedTestingUsers));
      } catch (error) {
        dispatch(addTestingUsers([]));
      }
    }
  }, [testingUsers, dispatch]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const firebaseIdToken = await firebaseAuth.currentUser?.getIdToken();
        addAuthHeader(firebaseIdToken || '');
        dispatch(addLoggedUser(user.toJSON() as User));

        const identifyEvent = new amplitude.Identify();
        amplitude.identify(identifyEvent);
        identifyEvent.setOnce('firebase-uid', user.uid);
        identify(identifyEvent);
      } else {
        addAuthHeader(userData?.data.access_token || '');
        dispatch(addLoggedUser(null));
      }
      setIsAuthReady(true);
    });

    return () => unsubscribeAuth();
  }, [dispatch, firebaseAuth, userData?.data.access_token]);

  if (!isAuthReady)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <>
        <LoginModal />
        <SignupModal />
        <ForgotPassword />
      </>

      {children}
    </>
  );
};

type TServiceSignUp = {
  id: string;
};

type TServiceSignIn = {
  access_token: string;
  token_type: string;
};
