export const applyMask = (inputValue: string) => {
  let maskedValue = inputValue.replace(/\D/g, '');

  if (maskedValue.length > 2) {
    if (maskedValue.length <= 6) {
      maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(2, 6)}`;
    } else if (maskedValue.length <= 10) {
      maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(
        2,
        6
      )}-${maskedValue.slice(6, 10)}`;
    } else {
      maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(
        2,
        7
      )}-${maskedValue.slice(7, 11)}`;
    }
  }
  return maskedValue;
};
