import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import { styled, Theme, CSSObject, keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import RecordIcon from '@mui/icons-material/MicOutlined';
import NotesIcon from '@mui/icons-material/DescriptionOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import SecurityIcon from '@mui/icons-material/SecurityOutlined';

import { help, notes, root, terms } from '../../routes';

import { useIsMobile } from '@/app/helpers/hooks/useIsMobile';
import { Badge, Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/app/app-redux/store';
import { removeSideMenuNotification } from './sideMenuSlice';
import { useIsTestingUser } from '@/app/helpers/hooks/useIsTestingUser';
import {
  CloseMenuMobileButton,
  OpenMenuMobileButton,
} from './components/menu-mobile-button';
import { TestingTag } from './components/testing-tag';

const openedMixin = (theme: Theme, width: number | string): CSSObject => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'width',
})<{ width: number | string }>(({ theme, open, width }) => ({
  width,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, width),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme, width),
      backgroundColor: '#f5f3f1',
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      backgroundColor: '#f5f3f1',
    },
  }),
}));

export type TItemMenu = {
  icon: React.ElementType;
  text: string;
  className?: string;
  to: string;
  end?: boolean;
};

const items: TItemMenu[] = [
  {
    icon: RecordIcon,
    text: 'Gravações',
    to: root,
    end: true,
  },
  {
    icon: NotesIcon,
    text: 'Notas anteriores',
    to: notes,
  },
  {
    icon: HelpIcon,
    text: 'Ajuda',
    to: help,
  },
];

export const SideMenu = () => {
  const itemsWithNotification = useAppSelector(
    (state) => state.sideMenu.itemsWithNotification
  );
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const [isOpen, setIsOpen] = useState(isMobile ? false : true);

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleItemClick = (item: TItemMenu) => {
    if (isMobile) {
      handleDrawerClose();
    }
    dispatch(removeSideMenuNotification(item.text));
  };

  const isTestingVersion = useIsTestingUser();

  return (
    <Box display="flex" sx={{ backgroundColor: 'background.default' }}>
      <OpenMenuMobileButton onClick={handleDrawerOpen} />
      <Drawer variant="permanent" width={isMobile ? '100%' : 240} open={isOpen}>
        {isTestingVersion && <TestingTag />}
        <Box
          textAlign="center"
          sx={{
            display: 'flex',
            paddingTop: { xs: '16px', md: '0' },
            justifyContent: {
              xs: isOpen ? 'space-between' : 'center',
              md: 'center',
            },
          }}
        >
          <CloseMenuMobileButton onClick={handleDrawerClose} />
        </Box>

        <Box>
          <List>
            {items.map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                className={item?.className || ''}
              >
                <ListItemButton
                  component={NavLink}
                  to={item.to}
                  onClick={() => handleItemClick(item)}
                  end={item.end}
                  sx={{
                    '&.active': {
                      backgroundColor: 'secondary.main',
                      color: 'secondary.contrastText',
                      '& .MuiSvgIcon-root': {
                        color: 'secondary.contrastText',
                      },
                    },
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5,
                    borderBottom: `1px solid #eaeaea`,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: isOpen ? 1 : 0 }}
                  />
                  {itemsWithNotification.includes(item.text) && (
                    <AnimatedBadge
                      color="warning"
                      overlap="circular"
                      badgeContent=" "
                      variant="dot"
                      sx={{
                        marginTop: '-20px',
                        marginRight: '10px',
                      }}
                    />
                  )}
                </ListItemButton>
                <Divider />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box mt="35px" sx={{ display: { xs: 'none', sm: 'block' } }}>
        <DrawerHeader className="side-menu-button">
          <IconButton onClick={isOpen ? handleDrawerClose : handleDrawerOpen}>
            {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
      </Box>
    </Box>
  );
};

const blink = keyframes`
 0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const AnimatedBadge = styled(Badge)`
  animation: ${blink} 2s linear infinite;
`;
