import { useEffect } from 'react';
import {
  Outlet,
  createBrowserRouter,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Root } from './screens/root';
import { help, notes, policy, root, terms as termsRoute } from './routes';
import { Recording } from './screens/recording';
import { AllNotes } from './screens/all-notes';
import { Help } from './screens/help/help';
import { Terms } from './screens/terms';
import { AppProviders } from './app-providers';
import { useAppSelector } from './app-redux/store';
import { Policy } from './screens/terms/policy';

const ProtectedRoutes = () => {
  const { loggedUser } = useAppSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedUser) {
      navigate(root);
      setSearchParams((prevParams) => {
        prevParams.set('auth', 'login');
        return prevParams;
      });
    }
  }, [loggedUser, navigate, searchParams, setSearchParams]);

  if (!loggedUser) return null;

  return <Outlet />;
};

export const router = createBrowserRouter([
  {
    path: root,
    element: (
      <AppProviders>
        <Root />
      </AppProviders>
    ),
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: notes,
            element: <AllNotes />,
          },
        ],
      },
      {
        path: root,
        element: <Recording />,
      },
      {
        path: termsRoute,
        element: <Terms />,
      },
      {
        path: policy,
        element: <Policy />,
      },
      {
        path: help,
        element: <Help />,
      },
    ],
  },
]);
