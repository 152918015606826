import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  itemsWithNotification: [] as string[],
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    addSideMenuNotification: (state, action: PayloadAction<string>) => {
      state.itemsWithNotification.push(action.payload);
    },
    removeSideMenuNotification: (state, action: PayloadAction<string>) => {
      state.itemsWithNotification = state.itemsWithNotification.filter(
        (item) => item !== action.payload
      );
    },
  },
});

export const { addSideMenuNotification, removeSideMenuNotification } =
  sideMenuSlice.actions;

export default sideMenuSlice.reducer;
