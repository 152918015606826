import { Box, Typography, styled } from '@mui/material';
import Joyride, { Step, TooltipRenderProps } from 'react-joyride';

type TTutorial = {
  setRunTutorial: React.Dispatch<React.SetStateAction<boolean>>;
  runTutorial: boolean;
  steps: Step[];
};

export const Tutorial = (props: TTutorial) => {
  if (!props.runTutorial) return null;

  return (
    <Joyride
      run={true}
      steps={props.steps}
      callback={(curr) => {
        if (curr.lifecycle === 'complete') {
          props.setRunTutorial(false);
        }
      }}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 995,
          },
          arrow: {
            length: 8,
            spread: 10,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 995,
        },
      }}
      tooltipComponent={Tooltip}
    />
  );
};

const CustomTooltip = styled(Box)`
  width: 300px;
  text-align: justify;
  padding: 8px;
`;

function Tooltip({ step, tooltipProps }: TooltipRenderProps) {
  return (
    <CustomTooltip {...tooltipProps} bgcolor="#FFF">
      <Box padding="md">
        {step.title && <Typography variant="caption">{step.title}</Typography>}
        {step.content && <Box>{step.content}</Box>}
      </Box>
    </CustomTooltip>
  );
}
