import { getWhatsappLink } from '@/app/components/fixed-whatsapp-button';
import { getAuthId } from '@/app/helpers/services';

import { Box, Link, Typography } from '@mui/material';

export const Help = () => {
  return (
    <Box maxWidth={'680px'}>
      <Typography variant="subtitle2">Como podemos ajudar?</Typography>
      <Box my="32px">
        <Typography variant="body1">
          Se você estiver tendo problemas com o sistema carecode, clique no link
          abaixo para obter ajuda. nosso time está à disposição no whatsApp,
          basta usar o ícone abaixo para entrar em contato. <br /> <br /> Se
          quiser saber mais sobre nossos termos de uso e política de
          privacidade, você pode encontrar as informações nos links disponíveis.
        </Typography>
      </Box>
      <ul>
        <li>
          <Link
            href={getWhatsappLink('Quero ajuda com meu microfone')}
            underline="hover"
            target="_blank"
            rel="noreferrer noopener"
          >
            Ajuda com microfone
          </Link>
        </li>
        <li>
          <Link
            href={getWhatsappLink('Quero saber mais sobre os termos de uso')}
            underline="hover"
            target="_blank"
            rel="noreferrer noopener"
          >
            Termos de uso
          </Link>
        </li>
        <li>
          <Link
            href={getWhatsappLink('Quero saber mais sobre sigilo dos dados')}
            underline="hover"
            target="_blank"
            rel="noreferrer noopener"
          >
            Política de privacidade
          </Link>
        </li>
      </ul>
      <Box>ID: {getAuthId()}</Box>
    </Box>
  );
};
