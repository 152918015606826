import { Box, Chip } from '@mui/material';

export const TestingTag = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '8px',
        left: 0,
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Chip
        size="small"
        variant="outlined"
        color="warning"
        label="testing version"
      />
    </Box>
  );
};
