import { formatDate } from '@/app/helpers/dates';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Note, openNote } from '@/app/modules/full-note/noteSlice';
import { queryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { NotesTable } from './components/notes-table';
import { FullNote } from '../../modules/full-note';
import { useAppDispatch } from '@/app/app-redux/store';
import { axiosInstance } from '@/app/helpers/services';
import { TServiceMedicalNotes } from '@/app/modules/player/hooks/useConsultationHandler/useCreateMedicalNote';
import { useRef, useState } from 'react';
import { CustomDialog } from '@/app/components/custom-dialog';

export const notesQuery = queryOptions({
  queryKey: ['notes'],
  queryFn: () => axiosInstance.get<TServiceMedicalNotes[]>('v2/medical_notes'),
});

export const AllNotes = () => {
  const [isOpenConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const result = useQuery({
    ...notesQuery,
    enabled: true,
  });

  const deleteNoteMutation = useMutation({
    mutationKey: ['deleteNote'],
    mutationFn: (noteId: string) =>
      axiosInstance.delete(`/v2/medical_notes/${noteId}`),
  });

  const notesData = result.data;
  const dispatch = useAppDispatch();

  const notes = (notesData?.data || []).map(normalizeNote);

  const handleOpenNote = (currentRow: Note, index: number) => {
    dispatch(
      openNote({
        noteId: currentRow.id,
        isOpen: true,
        noteTemplate: currentRow.note_template,
      })
    );
  };

  const currentRowToDelete = useRef<string>('');

  const openDeleteNotConfirm = async (currentRow: Note, index: number) => {
    currentRowToDelete.current = currentRow.id;
    setOpenConfirmationDialog(true);
  };

  const handleDeleteNote = async () => {
    const resp = await deleteNoteMutation.mutateAsync(
      currentRowToDelete.current
    );
    console.log({ resp });
    result.refetch();
  };

  return (
    <Box sx={{ paddingBottom: '20%' }}>
      <Typography variant="subtitle2">Histórico de notas</Typography>

      <Box
        mt="16px"
        overflow={'scroll'}
        sx={{ height: { xs: '100%', md: '65vh' } }}
      >
        <NotesTable
          rows={notes || []}
          onRowClick={handleOpenNote}
          onClickExclude={openDeleteNotConfirm}
        />
        {result.isLoading && (
          <Box mt="16px" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
      <CustomDialog
        onClose={() => {
          setOpenConfirmationDialog(false);
        }}
        dialogProps={{
          open: isOpenConfirmationDialog,
          maxWidth: 'xs',
        }}
      >
        <Typography variant="h6">Tem certeza que deseja excluir?</Typography>
        <Box display="flex" justifyContent="space-between" mt="16px">
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenConfirmationDialog(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={() => {
              setOpenConfirmationDialog(false);
              handleDeleteNote();
            }}
          >
            Excluir
          </Button>
        </Box>
      </CustomDialog>
      <FullNote />
    </Box>
  );
};

export const normalizeNote = (note: TServiceMedicalNotes): Note => ({
  consultationId: note.consultation_id,
  transcriptionId: note.transcription_id,
  notes: note.notes,
  id: note.id,
  note_template: note.note_template,
  createdAt: formatDate(note.created_at),
});
