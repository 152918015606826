import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum TemplateMap {
  'general_practitioner' = 'Clínico geral',
  'cardiologist' = 'Cardiologista',
  'soap' = 'SOAP',
  'consultation_report' = 'Relato de consulta (hospitalar/domiciliar)',
  'plastic_surgeon' = 'Cirurgião plástico',
  'orthopedist' = 'Ortopedista',
  'physiotherapist' = 'Fisioterapeuta',
  'tasks' = 'Tarefas',
}

export enum ConsultationMode {
  'in_person' = 'Presencial',
  'remote' = 'Remoto',
}

export type TCurrentTemplate = keyof typeof TemplateMap;
export type TCurrentConsultationMode = keyof typeof ConsultationMode;

const initialState = {
  currentTemplate: '' as TCurrentTemplate,
  currentConsultationMode: '' as TCurrentConsultationMode,
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    changeTeamplate: (state, action: PayloadAction<TCurrentTemplate>) => {
      state.currentTemplate = action.payload;
    },
    changeConsultationMode: (
      state,
      action: PayloadAction<TCurrentConsultationMode>
    ) => {
      state.currentConsultationMode = action.payload;
    },
  },
});

export const { changeTeamplate, changeConsultationMode } =
  templateSlice.actions;

export default templateSlice.reducer;
