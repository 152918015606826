import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { FormEventHandler, useEffect, useState } from 'react';
import { dialCodes } from '../dial-codes';
import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from '@/app/helpers/services';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { addError } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';
import { errors } from '@/app/helpers/errors';
import { getWhatsappLink } from '@/app/components/fixed-whatsapp-button';
import { CustomDialog } from '@/app/components/custom-dialog';
import { closeDialog } from '../dialogSlice';
import { trackingEvent } from '@/app/helpers/events/events';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';

type TServiceSavePhoneVariables = {
  phone_number: string;
};

export const ContactDialog = () => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState('');
  const [dialCode, setDialCode] = useState<string | null>('+55');

  const [hasPhoneError, setHasPhoneError] = useState(false);
  const [hasDialCodeError, setHasDialCodeError] = useState(false);
  const [isShowAgainChecked, setIsShowAgainChecked] = useState(false);

  const fullPhoneNumbner = `${dialCode}${phone}`;

  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const savePhoneMutation = useMutation({
    mutationFn: ({ phone_number }: TServiceSavePhoneVariables) =>
      axiosInstance.patch(`/user`, {
        phone_number,
      }),
    onSuccess: () => {
      trackFuncWithDefaultRecordingProps(
        trackingEvent['Asking Feedback Submited'],
        {
          phone_number: fullPhoneNumbner,
          success: true,
        }
      );
      localStorage.setItem('dontShowFeedbackAgain', 'true');
    },
    onError: (error: AxiosError) => {
      trackFuncWithDefaultRecordingProps(
        trackingEvent['Asking Feedback Submited'],
        {
          phone_number: fullPhoneNumbner,
          success: false,
        }
      );
      if (error.response?.status === 422) {
        return setHasPhoneError(true);
      }
      dispatch(
        addError({
          errors: [
            {
              title: errors.generic.title,
              message: errors.generic.message,
            },
          ],
        })
      );
    },
  });

  const handleChange = (value: string) => {
    setPhone(value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    setHasPhoneError(false);
    setHasDialCodeError(false);
    if (!phone.length) {
      setHasPhoneError(true);
      return;
    }
    if (!dialCode) {
      setHasDialCodeError(true);
      return;
    }

    savePhoneMutation.mutate({
      phone_number: fullPhoneNumbner,
    });
  };

  const handleChangeShowAgain = () => {
    setIsShowAgainChecked(!isShowAgainChecked);
  };

  const handleModalClose = () => {
    if (isShowAgainChecked) {
      localStorage.setItem('dontShowFeedbackAgain', String(isShowAgainChecked));
    }
    trackFuncWithDefaultRecordingProps(
      trackingEvent['Asking Feedback Closed'],
      {
        dont_show_feedback_again: isShowAgainChecked,
      }
    );
    dispatch(closeDialog());
  };

  const handleWhatsappClick = () => {
    trackFuncWithDefaultRecordingProps(
      trackingEvent['Asking Feedback Whatsapp Link Selected']
    );
  };

  useEffect(() => {
    trackFuncWithDefaultRecordingProps(trackingEvent['Asking Feedback Opened']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomDialog open={true} onClose={handleModalClose}>
      {savePhoneMutation.isSuccess ? (
        <Gratitudes />
      ) : (
        <Box padding="32px">
          <Typography variant="subtitle2" mb="8px" fontWeight={900}>
            Ajude-nos a melhorar
          </Typography>
          <Typography variant="body1">
            Sua participação é muito importe para nós da carecode. Compartilhe
            seu Número!
          </Typography>
          <Box mt="36px">
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex' }}>
                <Box width="50%" mr="8px">
                  <Autocomplete
                    fullWidth
                    value={dialCode}
                    onChange={(event, newValue) => {
                      setDialCode(newValue);
                    }}
                    options={[
                      ...new Set(dialCodes.map((item) => item.dial_code)),
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="código do país"
                        placeholder="ex: +55 para BR"
                        error={hasDialCodeError}
                        helperText={hasDialCodeError && 'código inválido'}
                      />
                    )}
                  />
                </Box>
                <Box width="100%">
                  <TextField
                    fullWidth
                    label={
                      dialCode === '+55'
                        ? 'digite seu telefone com DDD'
                        : 'telefone'
                    }
                    value={phone}
                    onChange={(e) => handleChange(e.target.value)}
                    error={hasPhoneError}
                    helperText={hasPhoneError && 'telefone inválido'}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', marginTop: '8px' }}>
                <Box mr="8px" width="100%">
                  <Box>
                    <Typography variant="caption">
                      Caso não queira informar seu número de telefone, você pode
                      enviar seu feedback para o{' '}
                      <a
                        href={getWhatsappLink('Quero enviar um feedback')}
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleWhatsappClick}
                      >
                        nosso whatsapp
                      </a>
                    </Typography>
                  </Box>
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  sx={{ height: '48px', width: '120px' }}
                  color="secondary"
                >
                  enviar
                </Button>
              </Box>
            </form>
            <Box mt="16px" mb="16px">
              <Divider />
            </Box>
          </Box>
          <Box mt="16px" sx={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isShowAgainChecked}
                  onChange={handleChangeShowAgain}
                />
              }
              label={
                <Typography variant="body2">Não mostrar novamente</Typography>
              }
            />
          </Box>
        </Box>
      )}
    </CustomDialog>
  );
};

const Gratitudes = () => {
  return (
    <Box padding="32px">
      <Typography variant="subtitle2">
        agradecemos por sua contribuição!
      </Typography>
      <Box mt="8  px">
        <Typography variant="body1">entraremos em contato em breve</Typography>
      </Box>
    </Box>
  );
};
