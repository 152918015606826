import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import * as S from './styles';
import { createRef, useRef } from 'react';

export type TMultiSelectOptions = {
  label: string;
  value: string;
  noIcon?: boolean;
  optionRef?: React.RefObject<HTMLLIElement>;
  subItems?: {
    label: string;
  }[];
};

type TOnClickInfo = (option: TMultiSelectOptions) => void;

type TMultilineSelect = {
  label: string;
  value: string;
  onChange:
    | ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
    | undefined;
  options: TMultiSelectOptions[];
  width?: number;
  onClickInfo?: TOnClickInfo;
  hideIcon?: boolean;
};

export const MultilineSelect = (props: TMultilineSelect) => {
  const elementsRef = useRef(
    props.options.map(() => createRef<HTMLLIElement>())
  );

  const selectRef = useRef<typeof Select>(null);

  return (
    <FormControl
      fullWidth
      sx={{
        '.Mui-focused': {
          '& .MuiTypography-root': {
            display: 'block',
          },
        },
      }}
    >
      <InputLabel
        // shrink={true}
        id={props.label.replace(/ /g, '-')}
        size="small"
        color="secondary"
      >
        {props.label}
      </InputLabel>
      <Select
        color="secondary"
        ref={selectRef}
        fullWidth
        displayEmpty
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        labelId={`${props.label.replace(/ /g, '-')}-label`}
        id={`${props.label.replace(/ /g, '-')}-select`}
        size="small"
        renderValue={(value) => {
          if (value.length === 0) {
            return (
              <Box>
                <Typography
                  sx={{
                    color: '#A9A9A9',
                    display: 'none',
                  }}
                >
                  Escolha
                </Typography>
              </Box>
            );
          }
          return (
            <Box>
              {props.options.find((o) => o.value === value)?.label || ''}
            </Box>
          );
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          sx: {
            '&& .Mui-selected': {
              backgroundColor: 'secondary.contrastText',
              '&:hover': {
                backgroundColor: 'secondary.contrastText',
              },
            },
          },
        }}
      >
        {props.options.map((option, index) => {
          return (
            <S.MultilineMenuItem
              ref={elementsRef.current[index]}
              key={option.label}
              value={option.value}
              width={props.width}
              color="secondary"
            >
              {option.label}
              <Box
                width="100%"
                display="flex"
                justifyContent="space-around"
                alignItems="space-around"
              >
                <Box width="90%">
                  <ul>
                    {(option.subItems || [])?.map((subItem) => {
                      return (
                        <li key={subItem.label}>
                          <Tooltip title={subItem.label}>
                            <Typography variant="caption" color="secondary">
                              {subItem.label}
                            </Typography>
                          </Tooltip>
                        </li>
                      );
                    })}
                  </ul>
                </Box>

                {!props.hideIcon && (
                  <Box
                    display="flex"
                    width="10%"
                    height="100%"
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    {!option.noIcon && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();

                          props.onClickInfo?.({
                            ...option,
                            optionRef: elementsRef.current[index],
                          });
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </Box>
            </S.MultilineMenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
