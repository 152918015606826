import { ReactNode } from 'react';
import { TMultiSelectOptions } from './components/multiline-select/multiline-select';
import { TemplateMap } from './templateSlice';
import { getKeyByStringValue } from '@/app/helpers/type-helpers';

import { DefaultMedicalNoteExample } from './components/default-medical-note-example';
import { DefaultReportExample } from './components/default-report-example';

export type TCurrentOptionInfo = TMultiSelectOptions & {
  description: string;
  noteExampleComponent: () => ReactNode;
};

export const optionsInfoModal: TCurrentOptionInfo[] = [
  {
    label: TemplateMap.cardiologist,
    value: getKeyByStringValue('cardiologist', TemplateMap),
    noteExampleComponent: () => (
      <DefaultMedicalNoteExample
        topics={[
          {
            title: 'Antecedentes pessoais',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'História da moléstia atual',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Dados antropométricos',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Impressões',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Conduta',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Observações',
            description: 'Descrições mais detalhadas',
          },
        ]}
      />
    ),
    description:
      'Modelo de registro indicado para atuação na especialidade de cardiologia <br /> <br />Veja abaixo modelo de registro esperado após gravação',
  },
  {
    label: TemplateMap.soap,
    value: getKeyByStringValue('soap', TemplateMap),
    description:
      'SOAP: Registro Médico Orientado por Problemas, permite registrar de forma sintética e estruturada as questões subjetivas do paciente, as impressões objetivas, as impressões diagnósticas e conduta <br /><br />Veja abaixo modelo de registro esperado após gravação',
    noteExampleComponent: () => (
      <DefaultMedicalNoteExample
        topics={[
          { title: 'Subjetivo', description: 'Descrições mais detalhadas' },
          { title: 'Objetivo', description: 'Descrições mais detalhadas' },
          { title: 'Avaliação', description: 'Descrições mais detalhadas' },
          { title: 'Plano', description: 'Descrições mais detalhadas' },
        ]}
      />
    ),
  },
  {
    label: TemplateMap.general_practitioner,
    value: getKeyByStringValue('general_practitioner', TemplateMap),
    description:
      'Modelo de registro indicado para atuações em áreas abrangentes, com informações mais completas divididas em tópicos convencionais da anamnese<br/><br/>Veja abaixo modelo de registro esperado após gravação',
    noteExampleComponent: () => (
      <DefaultMedicalNoteExample
        topics={[
          {
            title: 'Histórico da moléstia atual',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Antecedentes pessoais',
            description: 'Descrições mais detalhadas',
          },
          { title: 'Alergias', description: 'Descrições mais detalhadas' },
          {
            title: 'Histórico familiar',
            description: 'Descrições mais detalhadas',
          },
          { title: 'Impressões', description: 'Descrições mais detalhadas' },
          { title: 'Conduta', description: 'Descrições mais detalhadas' },
          { title: 'Observações', description: 'Descrições mais detalhadas' },
        ]}
      />
    ),
  },
  {
    label: TemplateMap.consultation_report,
    value: getKeyByStringValue('consultation_report', TemplateMap),
    description:
      'Modelo de registro para documentar informações após consulta médica ou hospitalar, destinado ao uso individual, fornecendo transcrição completa da gravação<br /><br />Veja abaixo modelo de registro esperado após gravação',
    noteExampleComponent: () => (
      <DefaultReportExample description="Paciente relatou dor de cabeça intensa nas últimas 24 horas, dor latejante na região frontal, sem náuseas ou vômitos. Teve sensibilidade à luz e ao som. Tomou analgésico sem alívio. Histórico de enxaqueca. Busca orientações para alívio imediato e atualmente não consegue trabalhar e parou de tomar remédios para dor por não sentir que surtiu o efeito desejado. " />
    ),
  },
  {
    label: TemplateMap.plastic_surgeon,
    value: getKeyByStringValue('plastic_surgeon', TemplateMap),
    description:
      'Modelo de registro indicado para atuação na especialidade de cirurgia plástica <br /> <br /> Veja abaixo modelo de registro esperado após gravação',
    noteExampleComponent: () => (
      <DefaultMedicalNoteExample
        topics={[
          {
            title: 'Queixa da paciente',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Histórico clínico',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Atividades físicas',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Histórico de medicamentos',
            description: 'Descrições mais detalhadas',
          },
          { title: 'Alergias', description: 'Descrições mais detalhadas' },
          {
            title: 'Histórico cirúrgico',
            description: 'Descrições mais detalhadas',
          },
          { title: 'Exame físico', description: 'Descrições mais detalhadas' },
          { title: 'Diagnóstico', description: 'Descrições mais detalhadas' },
          {
            title: 'Planejamento cirúrgico',
            description: 'Descrições mais detalhadas',
          },
          { title: 'Outros', description: 'Descrições mais detalhadas' },
        ]}
      />
    ),
  },
  {
    label: TemplateMap.orthopedist,
    value: getKeyByStringValue('orthopedist', TemplateMap),
    description:
      'Modelo de registro indicado para atuação na especialidade de ortopedia <br /> <br /> Veja abaixo modelo de registro esperado após gravação',
    noteExampleComponent: () => (
      <DefaultMedicalNoteExample
        topics={[
          {
            title: 'queixa da paciente',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'histórico clínico',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'histórico de medicamentos',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'histórico cirúrgico',
            description: 'Descrições mais detalhadas',
          },
          { title: 'exame físico', description: 'Descrições mais detalhadas' },
          {
            title: 'exames de imagem',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'conduta médica',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'conduta médica',
            description: 'Descrições mais detalhadas',
          },
        ]}
      />
    ),
  },
  {
    label: TemplateMap.physiotherapist,
    value: getKeyByStringValue('physiotherapist', TemplateMap),
    description:
      'Modelo de registro indicado para atuação na especialidade de fisioterapia <br /> <br /> Veja abaixo modelo de registro esperado após gravação',
    noteExampleComponent: () => (
      <DefaultMedicalNoteExample
        topics={[
          {
            title: 'Queixa da paciente',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Histórico clínico',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Qualidade de vida e hábitos',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Histórico de atividade física',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Histórico de medicamentos ',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Histórico cirúrgico',
            description: 'Descrições mais detalhadas',
          },
          { title: 'Exame físico', description: 'Descrições mais detalhadas' },
          {
            title: 'Exames de imagem',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Conduta da Fisioteurapeuta',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Diagnóstico disfuncional',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Evolução das sessões',
            description: 'Descrições mais detalhadas',
          },
          {
            title: 'Prescrições e orientações',
            description: 'Descrições mais detalhadas',
          },
          { title: 'Outros', description: 'Descrições mais detalhadas' },
        ]}
      />
    ),
  },
];
