import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import noteSlice from '../modules/full-note/noteSlice';
import templateSlice from '../modules/template-selector/templateSlice';
import recordingSlice from '../modules/player/recordingSlice';
import errorHandlerSlice from '../app-providers/error-handler-provider/errorHandlerSlice';
import sideMenuSlice from '../modules/side-menu/sideMenuSlice';
import authSlice from '../modules/auth/authSlice';
import dialogSlice from '../modules/dialog-module/dialogSlice';

export const store = configureStore({
  reducer: {
    note: noteSlice,
    template: templateSlice,
    recording: recordingSlice,
    errorHandler: errorHandlerSlice,
    sideMenu: sideMenuSlice,
    auth: authSlice,
    dialog: dialogSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
