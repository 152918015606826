import { useAppDispatch, useAppSelector } from '@/app/app-redux/store';
import { trackingEvent } from '@/app/helpers/events/events';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';
import useEffectOnce from '@/app/helpers/hooks/useEffectOnce';

import { useStatusTracking } from '@/app/modules/player/hooks/useStatusTracking';
import { addRecordingId } from '@/app/modules/player/recordingSlice';
import { captureException } from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { useConsultationHandler } from './useConsultationHandler';
import { TInternalStatus } from '../components/recording-control/recording-control';

const checkMicrophonePermission = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
    stream.getAudioTracks().forEach((track) => track.stop());

    return true;
  } catch (_) {
    return false;
  }
};

export const useRecordingHandlers = () => {
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    error,
    clearBlobUrl,
    pauseRecording,
    resumeRecording,
  } = useReactMediaRecorder({
    video: false,
    blobPropertyBag: { type: 'audio/mp3' },
  });
  const dispatch = useAppDispatch();
  const { currentTemplate } = useAppSelector((state) => state.template);
  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const recordingId = useRef<string>(''); // only used for tracking

  const { isProcessing, processingLabel } = useConsultationHandler({
    mediaBlobUrl,
    template: currentTemplate,
    clearBlobUrl,
  });
  const [runTutorial, setRunTutorial] = useState(false);
  const [internalStatus, setInternalStatus] = useState<TInternalStatus>(
    TInternalStatus.uninitialized
  );

  const handleStartRecording = async () => {
    recordingId.current = crypto.randomUUID();
    dispatch(addRecordingId({ recordingId: recordingId.current }));

    const hasPermission = await checkMicrophonePermission();
    if (!hasPermission) {
      setInternalStatus(TInternalStatus.unauthorized);
      return;
    }

    if (!currentTemplate) {
      setRunTutorial(true);
      return;
    }

    startRecording();
    trackFuncWithDefaultRecordingProps(
      trackingEvent['Microphone Authorization Asked']
    );
  };

  const handleStopRecording = () => {
    setInternalStatus(TInternalStatus.uninitialized);
    stopRecording();
  };

  const handleTogglePause = () => {
    if (status === 'recording') {
      pauseRecording();
    } else {
      resumeRecording();
    }
  };

  useEffect(() => {
    if (error) {
      captureException(new Error(error));
    }
  }, [error]);

  useEffectOnce(() => {
    dispatch(addRecordingId({ recordingId: '' }));
  });

  useStatusTracking({
    recordingId: recordingId.current,
    status,
  });

  return {
    handleStopRecording,
    handleStartRecording,
    handleTogglePause,
    internalStatus,
    isProcessing,
    processingLabel,
    status,
    runTutorial,
    setRunTutorial,
  };
};
