import { useState } from 'react';
import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PersonIcon from '@mui/icons-material/Person';
import { CustomDialog } from '@/app/components/custom-dialog';
import { isValidEmailRegex } from '../signup-modal/useSignupForm';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { dispatchFirebaseError } from '../../helpers';
import { useAppDispatch } from '@/app/app-redux/store';
import logo from '@/assets/logo.png';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';
import { trackingEvent } from '@/app/helpers/events/events';
import { FirebaseError } from 'firebase/app';

const schema = yup
  .object({
    email: yup
      .string()
      .matches(isValidEmailRegex, 'Por favor, preencha um e-mail válido')
      .required('Por favor, preencha um e-mail'),
  })
  .required();

type TForgotPasswordInput = {
  email: string;
};

export const ForgotPassword = () => {
  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const authParam = searchParams.get('auth');
  const isModalOpen = authParam === 'forgotPassword';
  const [isPasswordResetSent, setIsPasswordResetSent] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
    getValues,
  } = useForm<TForgotPasswordInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: TForgotPasswordInput) => {
    try {
      const auth = getAuth();
      auth.useDeviceLanguage();
      await sendPasswordResetEmail(auth, data?.email);
      setIsPasswordResetSent(true);
      trackFuncWithDefaultRecordingProps(
        trackingEvent['User Reseted Password'],
        {
          success: true,
        }
      );
    } catch (error) {
      setIsPasswordResetSent(false);
      dispatchFirebaseError(dispatch)(error);
      if (error instanceof FirebaseError) {
        trackFuncWithDefaultRecordingProps(
          trackingEvent['User Reseted Password'],
          {
            success: false,
            error: error?.code || '',
          }
        );
      }
    }
  };

  const rebuildForm = () => {
    reset();
    setIsPasswordResetSent(false);
  };

  const backToLogin = () => {
    rebuildForm();
    setSearchParams({ auth: 'login' });
  };

  const getSuccessMessage = () => {
    const email = getValues('email');
    return `Se uma conta estiver associada a esse e-mail ${email}, um link de redefinição foi enviado`;
  };

  const successMessage = getSuccessMessage();

  return (
    <CustomDialog
      withBackbutton
      open={isModalOpen}
      onClose={() => setSearchParams({ auth: '' })}
    >
      <Box paddingBottom={(theme) => theme.spacing(2)}>
        <Box sx={{ marginBottom: '48px', textAlign: 'center' }}>
          <img src={logo} alt="Logo" width="141px" />
        </Box>
        {isPasswordResetSent ? (
          <Box>
            <Typography variant="h6" textAlign="center">
              {successMessage}
            </Typography>
            <Typography variant="body2" textAlign="center" mt="8px">
              Verifique sua caixa de entrada e siga as instruções para redefinir
              sua senha
            </Typography>
            <Box
              textAlign="center"
              mt={(theme) => theme.spacing(1)}
              onClick={backToLogin}
            >
              <Button size="large" variant="text" color="secondary">
                Voltar ao login
              </Button>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box mb={(theme) => theme.spacing(3)} textAlign="center">
              <Typography variant="h6">Esqueceu a senha?</Typography>
              <Typography variant="body2">
                Sem problemas! Informe seu e-mail que enviaremos um código de
                acesso{' '}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth>
                <TextField
                  {...register('email')}
                  error={Boolean(formErrors.email?.message)}
                  helperText={formErrors.email?.message}
                  placeholder="Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Box mt={(theme) => theme.spacing(1)}>
                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Enviar Código
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Box>
    </CustomDialog>
  );
};
