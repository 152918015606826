import { useEffect, useRef, useState } from "react";
import { StatusMessages } from "react-media-recorder";

type TUseRecordingTime = {
	status: StatusMessages
}

export const useRecordingTime = ({status}: TUseRecordingTime) => {
	const [recordingTime, setRecordingTime] = useState<number>(0);
	const interval = useRef<NodeJS.Timeout>();

	  useEffect(() => {
    if (status === 'recording') {
      interval.current = setInterval(() => {
        setRecordingTime((previousTime) => previousTime + 1);
      }, 1000);
    } else {
      clearInterval(interval.current);
    }
  }, [status]);

	const minutes = Math.floor(recordingTime / 60); // 1 minute = 60 seconds
  const seconds = recordingTime % 60; // 1 minute = 60 seconds
  const hours = Math.floor(recordingTime / 3600); // 1 hour = 3600 seconds

	return {
		minutes: minutes < 10 ? `0${minutes}` : minutes,
		seconds: seconds < 10 ? `0${seconds}` : seconds,
		hours: hours < 10 ? `0${hours}` : hours,
	}
}