import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum DialogEnum {
  'request-contact' = 'request-contact',
}

interface DialogState {
  isOpen: boolean;
  dialogType: DialogEnum | null;
}

const initialState: DialogState = {
  isOpen: false,
  dialogType: null,
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<{ dialogType: DialogEnum }>) => {
      state.isOpen = true;
      state.dialogType = action.payload.dialogType;
    },
    closeDialog: (state) => {
      state.isOpen = false;
      state.dialogType = null;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
