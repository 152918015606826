import { getAuth, signOut } from 'firebase/auth';
import { useSearchParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/app/app-redux/store';
import { addLoggedUser } from '../../auth/authSlice';
import { addError } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';
import { errors } from '@/app/helpers/errors';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
export const LoginButton = () => {
  const [_, setSearchParams] = useSearchParams();

  const { loggedUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        dispatch(addLoggedUser(null));
      })
      .catch(() => {
        dispatch(addError({ errors: [errors.generic] }));
      });
  };

  const handleLogin = () => {
    setSearchParams({ auth: 'login' });
  };

  return (
    <Box>
      <Button
        variant={'outlined'}
        startIcon={loggedUser ? <LogoutIcon /> : null}
        size="small"
        onClick={loggedUser ? handleLogout : handleLogin}
        color="secondary"
      >
        {loggedUser ? 'Sair' : 'Entrar'}
      </Button>
    </Box>
  );
};
