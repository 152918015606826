import { useNavigate } from 'react-router-dom';
import React from 'react';
import {
  Dialog as MUIDialog,
  IconButton,
  DialogProps as MUIDialogProps,
  DialogContent,
} from '@mui/material';

import { useIsMobile } from '@/app/helpers/hooks/useIsMobile';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { root } from '@/app/routes';

type TDialog = {
  dialogProps?: MUIDialogProps;
  onClose: () => void;
  open?: boolean;
  children: React.ReactNode;
  withBackbutton?: boolean;
};

export const CustomDialog = (props: TDialog) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleBack = () => {
    const hasHistory = window.history.state.idx > 0;

    if (!hasHistory) {
      return navigate(root);
    }
    navigate(-1);
  };

  return (
    <MUIDialog
      open={props.open || false}
      fullScreen={isMobile}
      onClose={props.onClose}
      fullWidth
      maxWidth="sm"
      {...props.dialogProps}
    >
      {props.withBackbutton && (
        <IconButton
          aria-label="back"
          onClick={handleBack}
          sx={{
            position: 'absolute',
            left: '8px',
            top: '24px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <BackIcon />
        </IconButton>
      )}
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '24px',
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: '32px' }}>
        {props.children}
      </DialogContent>
    </MUIDialog>
  );
};
