import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import LockIcon from '@mui/icons-material/LockOutlined';
import PersonIcon from '@mui/icons-material/Person';

import { CustomDialog } from '@/app/components/custom-dialog';

import logo from '@/assets/logo.png';

import { TextFieldPassword } from '../text-field-password';
import { useLoginForm } from './useLoginForm';

export const LoginModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const authParam = searchParams.get('auth');

  const isModalOpen = authParam === 'login';

  const { onSubmit, register, formErrors } = useLoginForm();

  const openSignUp: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setSearchParams({ auth: 'signup' });
  };
  const openForgotPassword: React.MouseEventHandler<HTMLAnchorElement> = (
    e
  ) => {
    e.preventDefault();
    setSearchParams({ auth: 'forgotPassword' });
  };

  return (
    <CustomDialog
      open={isModalOpen}
      withBackbutton
      onClose={() => setSearchParams({ auth: '' })}
    >
      <form onSubmit={onSubmit}>
        <Box>
          <Box sx={{ marginBottom: '48px', textAlign: 'center' }}>
            <img src={logo} alt="Logo" width="141px" />
          </Box>
          <Box sx={{ marginBottom: '32px', textAlign: 'center' }}>
            <Box marginBottom="24px" textAlign="center">
              <Typography variant="h6">Faça login ou cadastre-se</Typography>
              <Typography variant="body2">
                Para acessar o histórico de notas e mais funcionalidades do
                copiloto
              </Typography>
            </Box>
            <Typography variant="body2">
              Não tem conta?{' '}
              <Link
                underline="hover"
                onClick={openSignUp}
                sx={{ cursor: 'pointer' }}
              >
                Cadastre-se
              </Link>
            </Typography>
          </Box>
          <Box mb="8px">
            <FormControl fullWidth>
              <TextField
                {...register('email')}
                error={Boolean(formErrors.email?.message)}
                helperText={formErrors.email?.message}
                placeholder="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextFieldPassword
                {...register('password')}
                error={Boolean(formErrors.password?.message)}
                helperText={formErrors.password?.message}
                placeholder="Senha"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
          <Box mt="8px">
            <Link
              onClick={openForgotPassword}
              underline="hover"
              sx={{ cursor: 'pointer' }}
            >
              <Typography variant="body2">Esqueci minha senha</Typography>
            </Link>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              marginBottom: '57px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              type="submit"
              size="large"
              variant="contained"
              fullWidth
              color="secondary"
            >
              Entrar
            </Button>
          </Box>
        </Box>
      </form>
    </CustomDialog>
  );
};
