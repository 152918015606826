import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/app-redux/store';
import { trackingEvent } from '@/app/helpers/events/events';

import {
  TCurrentTemplate,
  changeTeamplate,
} from '@/app/modules/template-selector/templateSlice';
import { Box, SelectChangeEvent, Typography } from '@mui/material';

import {
  MultilineSelect,
  TMultiSelectOptions,
} from './components/multiline-select/multiline-select';
import { registerTypeOptions } from './options';

import { SelectionModal } from './components/selection-modal';

import * as S from './styles';
import { useIsMobile } from '@/app/helpers/hooks/useIsMobile';
import { getWhatsappLink } from '@/app/components/fixed-whatsapp-button';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';

import { TCurrentOptionInfo, optionsInfoModal } from './options-modal-info';

export const TemplateSelector = () => {
  const { currentTemplate } = useAppSelector((state) => state.template);
  const dispatch = useAppDispatch();
  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const [isOpen, setIsOpen] = useState(false);
  const [currentOptionInfo, setCurrentOptionInfo] =
    useState<TCurrentOptionInfo>();

  const handleTemplateChange = (event: SelectChangeEvent) => {
    trackFuncWithDefaultRecordingProps(trackingEvent['Specialty Selected'], {
      specialty: event.target.value,
    });
    if (event.target.value === 'request_new_type') {
      window.open(
        getWhatsappLink('Quero um novo Tipo de registro'),
        '_blank',
        'noreferrer noopener'
      );
      return;
    }

    dispatch(changeTeamplate(event.target.value as TCurrentTemplate));
  };

  const handleOnClickInfo = (option: TMultiSelectOptions) => {
    const selectedOptionInfo =
      optionsInfoModal.find((info) => info.value === option.value) || null;
    if (selectedOptionInfo) {
      setIsOpen(true);
      trackFuncWithDefaultRecordingProps(
        trackingEvent['Register Type Information Opened'],
        {
          option: selectedOptionInfo.value,
        }
      );
      setCurrentOptionInfo({
        ...selectedOptionInfo,
        optionRef: option.optionRef,
      });
    }
  };

  const handleOnSelect = (
    value: string,
    selectItemRef?: React.RefObject<HTMLLIElement>
  ) => {
    setIsOpen(false);
    selectItemRef?.current?.click();
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const isMobile = useIsMobile();

  return (
    <>
      <Box sx={{ marginTop: (theme) => theme.spacing(5) }}>
        <Typography variant="body1" textAlign="center">
          Primeiro selecione o tipo de registro, clique no ícone para iniciar a
          gravação e depois receber a nota
        </Typography>
      </Box>
      <S.Wrapper>
        <Box className="required-selects">
          <MultilineSelect
            width={isMobile ? undefined : 400}
            label="Tipo de registro"
            options={registerTypeOptions}
            value={currentTemplate}
            onChange={handleTemplateChange}
            onClickInfo={handleOnClickInfo}
          />
        </Box>

        <SelectionModal
          isOpen={isOpen}
          label={currentOptionInfo?.label || ''}
          value={currentOptionInfo?.value || ''}
          description={currentOptionInfo?.description || ''}
          optionRef={currentOptionInfo?.optionRef}
          noteExampleComponent={
            currentOptionInfo?.noteExampleComponent || (() => null)
          }
          onClose={handleOnClose}
          onSelect={handleOnSelect}
        />
      </S.Wrapper>
    </>
  );
};
