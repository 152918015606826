// Import the functions you need from the SDKs you need
import { getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAuth } from 'firebase/auth';

import {
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_DOMAIN,
  VITE_FIREBASE_MEASUREMENT_ID,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
} from '../envs/envs';

const firebaseConfig = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: `${VITE_FIREBASE_DOMAIN}.firebaseapp.com`,
  projectId: `${VITE_FIREBASE_DOMAIN}`,
  storageBucket: `${VITE_FIREBASE_DOMAIN}.appspot.com`,
  messagingSenderId: VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: VITE_FIREBASE_APP_ID,
  measurementId: VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
try {
  const app = initializeApp(firebaseConfig);

  getAnalytics(app);
  getStorage(app);
  getAuth(app);
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 10; // 10 minutes
} catch (error) {
  console.log(error, 'Firebase init app');
}
