import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

type TestingUser = {
  id: string;
};

type TLoggedUser = User | null;

const initialState = {
  testingUsers: [] as TestingUser[],
  loggedUser: null as TLoggedUser,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addTestingUsers: (state, action: PayloadAction<TestingUser[]>) => {
      state.testingUsers = action.payload;
    },
    addLoggedUser: (state, action: PayloadAction<TLoggedUser>) => {
      state.loggedUser = action.payload;
    },
  },
});

export const { addTestingUsers, addLoggedUser } = authSlice.actions;
export default authSlice.reducer;
