import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TCurrentTemplate } from '../template-selector/templateSlice';

export type Note = {
  consultationId: string;
  note_template: TCurrentTemplate;
  createdAt: string;
  id: string;
  notes: string;
  transcriptionId: string;
};

type TOpenNote = {
  noteId: string;
  isOpen: boolean;
  noteTemplate?: TCurrentTemplate;
  regenerated?: { from: string; to: string };
};

const initialState = {
  notes: [] as Note[],
  openNote: {
    // noteId: 'a7bb5554-5336-4b78-9e7b-67a29470e331',
    // isOpen: true,
    noteId: '',
    isOpen: false,
  } as TOpenNote,
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    addNote: (state, action: PayloadAction<Note>) => {
      state.notes.push(action.payload);
    },
    openNote: (state, action: PayloadAction<TOpenNote>) => {
      state.openNote = {
        ...state.openNote,
        noteId: action.payload.noteId,
        isOpen: action.payload.isOpen,
        regenerated: action.payload.regenerated,
        noteTemplate: action.payload.noteTemplate,
      };
    },
  },
});

export const { addNote, openNote } = noteSlice.actions;

export default noteSlice.reducer;
