export const trackingEvent = {
  'Microphone Authorization Asked': 'Microphone Authorization Asked',
  'Microphone Authorization Granted': 'Microphone Authorization Granted',
  'Microphone Authorization Denied': 'Microphone Authorization Denied',
  'Recording Started': 'Recording Started',
  'Recording Paused': 'Recording Paused',
  'Recording Stopped': 'Recording Stopped',
  'Note Generated': 'Note Generated',
  'Note Visualized': 'Note Visualized',
  'Note Copied': 'Note Copied',
  'Specialty Selected': 'Specialty Selected',
  'Note Evaluated': 'Note Evaluated',
  'Consultation Mode Selected': 'Consultation Mode Selected',
  'Register Type Information Opened': 'Register Type Information Opened',
  'Note Editing Started': 'Note Editing Started',
  'Note Editing Ended': 'Note Editing Ended',
  // Asking Feedback Modal
  'Asking Feedback Opened': 'Asking Feedback Opened',
  'Asking Feedback Closed': 'Asking Feedback Closed',
  'Asking Feedback Submited': 'Asking Feedback Submited',
  'Asking Feedback Whatsapp Link Selected':
    'Asking Feedback Whatsapp Link Selected',
  'User Logged In': 'User Logged In',
  'User Registered': 'User Registered',
  'User Reseted Password': 'User Reseted Password',
};
