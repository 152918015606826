import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '@/app/helpers/services';
import { TCurrentTemplate } from '@/app/modules/template-selector/templateSlice';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';
import { trackingEvent } from '@/app/helpers/events/events';
import { notesQuery } from '@/app/screens/all-notes/all-notes';
import { getDiffTime } from '@/app/helpers/dates';
import { TProcessAudioReturn } from './useProcessAudio';

export const useCreateMedicalNote = () => {
  const queryClient = useQueryClient();

  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const medicalNotesMutation = useMutation({
    retry: false,
    mutationFn: ({
      transcriptionId,
      consultationId,
      noteTemplate,
      validateTranscription,
    }: TServiceMedicalNotesVariables) =>
      axiosInstance.post<TServiceMedicalNotes>('v2/medical_notes', {
        transcription_id: transcriptionId,
        consultation_id: consultationId,
        note_template: noteTemplate,
        validate_transcription: validateTranscription,
      }),
  });

  const updateNotesCache = (medicalNoteResponse: TServiceMedicalNotes) => {
    const previousNotes = queryClient.getQueryData(notesQuery.queryKey);
    const previousNotesData = previousNotes?.data || [];

    if (previousNotes) {
      queryClient.setQueryData(notesQuery.queryKey, {
        ...previousNotes,
        data: [medicalNoteResponse, ...previousNotesData],
      });
    }
  };

  const createMedicalNote = async (
    params: TCreateMedicalNoteParams
  ): Promise<{ medicalNoteId: string; noteTemplate: TCurrentTemplate }> => {
    const { data } = await medicalNotesMutation.mutateAsync({
      transcriptionId: params.transcriptionId,
      consultationId: params.consultationId,
      noteTemplate: params.template,
      validateTranscription: import.meta.env.MODE === 'production',
    });

    const { id: medicalNoteId, note_template: noteTemplate, notes } = data;

    trackFuncWithDefaultRecordingProps(trackingEvent['Note Generated'], {
      time_to_process: params.startedAt
        ? getDiffTime(new Date(), new Date(params.startedAt))
        : '',
      note_id: medicalNoteId,
      success: notes !== '',
      reprocessed: params.isReprocess,
      was_manually_reprocessed: params.wasManuallyReprocessed,
    });

    updateNotesCache(data);

    return { medicalNoteId, noteTemplate };
  };

  return {
    createMedicalNote,
  };
};

type TCreateMedicalNoteParams = TProcessAudioReturn & {
  template: TCurrentTemplate;
  isReprocess?: boolean;
  wasManuallyReprocessed?: boolean;
  startedAt?: string;
};

type TServiceMedicalNotesVariables = {
  transcriptionId: string;
  consultationId: string;
  noteTemplate: string;
  validateTranscription: boolean;
};

export type TServiceMedicalNotes = {
  transcription_id: string;
  consultation_id: string;
  id: string;
  notes: string;
  created_at: string;
  note_template: TCurrentTemplate;
  duration: number;
};
