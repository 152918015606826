import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Note } from '@/app/modules/full-note/noteSlice';
import { Box, Button, styled } from '@mui/material';
import { useRemoteConfig } from '@/app/infra/firebase/useRemoteConfig';

type TNotes = {
  rows: Note[];
  onRowClick: (currentRow: Note, index: number) => void;
  onClickExclude: (currentRow: Note, index: number) => void;
};

export const NotesTable = ({ rows, onRowClick, onClickExclude }: TNotes) => {
  const { data: isExcludeEnabled } = useRemoteConfig({
    name: 'exclude_note_enabled',
    type: 'boolean',
  });

  return (
    <Box
      sx={{
        width: '100%',
        display: 'table',
        tableLayout: 'fixed',
      }}
    >
      <TableContainer component={Paper} style={{ overflowX: 'initial' }}>
        <Table stickyHeader aria-label="table with notas">
          <TableHead>
            <TableRow>
              <TableCell>nota</TableCell>
              <TableCell>id</TableCell>
              <TableCell align="right">data</TableCell>
              {isExcludeEnabled && <TableCell align="right">excluir</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                hover
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                  onRowClick(row, index);
                }}
                sx={{
                  'cursor: pointer;&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell style={{ maxWidth: 320 }} align="left">
                  <ElipsisText>{row.notes}</ElipsisText>
                </TableCell>
                <TableCell
                  style={{ maxWidth: 100, paddingRight: 30 }}
                  align="left"
                >
                  <ElipsisText>{row.id}</ElipsisText>
                </TableCell>
                <TableCell align="right">{row.createdAt}</TableCell>
                {isExcludeEnabled && (
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      sx={{ borderRadius: 20 }}
                      onClick={(event) => {
                        event.stopPropagation();
                        onClickExclude(row, index);
                      }}
                    >
                      Excluir
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const ElipsisText = styled('span')`
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
`;
