import { useEffect, useRef, useState } from 'react';

import { useConsultationAsyncProcess } from './useConsultationAsyncProcess';

import { useFirebaseAudioUpload } from './useFirebaseAudioUpload';
import { TCurrentTemplate } from '@/app/modules/template-selector/templateSlice';
import { useRemoteConfig } from '@/app/infra/firebase/useRemoteConfig';
import { captureMessage } from '@/app/helpers/logging/logging';

type TUseConsultationHandler = {
  mediaBlobUrl?: string | null;
  template: TCurrentTemplate;
  clearBlobUrl: () => void;
};

export const useConsultationHandler = ({
  mediaBlobUrl,
  template,
  clearBlobUrl,
}: TUseConsultationHandler) => {
  const mediaBlobUrlRef = useRef<string | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const { data: isDebugAudioEnabled } = useRemoteConfig({
    name: 'download_audio_enabled',
    type: 'boolean',
  });

  useFirebaseAudioUpload(audioBlob);

  useEffect(() => {
    (async () => {
      if (mediaBlobUrl && mediaBlobUrl !== mediaBlobUrlRef.current) {
        captureMessage('Creating blob URL');

        const currentBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        setAudioBlob(currentBlob);

        if (isDebugAudioEnabled) {
          const a = window.document.createElement('a');
          a.href = mediaBlobUrl;
          mediaBlobUrlRef.current = mediaBlobUrl;
          a.download = 'audio.mp3';
          a.innerHTML = 'download';
          Object.assign(a.style, {
            position: 'absolute',
            bottom: 0,
            left: '50%',
            zIndex: 9999,
          });

          document.querySelector('#root')?.appendChild(a);
        }
      }
    })();
  }, [mediaBlobUrl, isDebugAudioEnabled]);

  const { isProcessing, processingLabel } = useConsultationAsyncProcess({
    audioBlob,
    template,
    clearBlobUrl,
  });

  return {
    isProcessing,
    processingLabel,
  };
};
