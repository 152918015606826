import * as Sentry from '@sentry/react';
import { VITE_SENTRY_DSN } from './envs/envs';

const replayConfigs = {
  replaysSessionSampleRate: 100,
  replaysOnErrorSampleRate: 100,
};

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/copiloto\.carecode\.com\.br/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: replayConfigs.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: replayConfigs.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
