import {
  differenceInSeconds,
  format as dateFnsFormat,
  subHours,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

const format: typeof dateFnsFormat = (date, format, options) => {
  const brUtcDate = subHours(date, 3);
  return dateFnsFormat(brUtcDate, format, options);
};

export const getCurrentDateFormatted = () => {
  return format(new Date(), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
};

export const formatDate = (date: string) => {
  try {
    return format(new Date(date), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
  } catch (error) {
    return '-';
  }
};

export const getDiffTime = (startDate: Date, endDate: Date) => {
  return differenceInSeconds(startDate, endDate);
};
