import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from '@/app/app-redux/store';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { removeErrors } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';

import { isValidEmailRegex } from '../signup-modal/useSignupForm';
import { dispatchFirebaseError } from '../../helpers';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';
import { trackingEvent } from '@/app/helpers/events/events';
import { FirebaseError } from 'firebase/app';

const schema = yup
  .object({
    email: yup
      .string()
      .matches(isValidEmailRegex, 'Por favor, preencha um e-mail válido')
      .required('Por favor, preencha um e-mail'),
    password: yup.string().required('Por favor, preencha uma senha'),
  })
  .required();

export type TLoginFormInput = {
  email: string;
  password: string;
};

export const useLoginForm = () => {
  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const dispatch = useAppDispatch();
  const auth = getAuth();
  const [, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<TLoginFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<TLoginFormInput> = async ({
    email,
    password,
  }) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      trackFuncWithDefaultRecordingProps(trackingEvent['User Logged In'], {
        success: true,
      });
      setSearchParams({ auth: '' });
      dispatch(removeErrors());
    } catch (error) {
      dispatchFirebaseError(dispatch)(error);
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        trackFuncWithDefaultRecordingProps(trackingEvent['User Logged In'], {
          success: false,
          error: errorCode,
        });
      }
    }
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    handleSubmit,
    formErrors,
  };
};
