import { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/EditOutlined';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import RenewIcon from '@mui/icons-material/AutorenewOutlined';

import { TEvaluation } from '../../full-note';
import * as S from './styles';
import { useIsMobile } from '@/app/helpers/hooks/useIsMobile';
import { MultilineSelect } from '../../../template-selector/components/multiline-select/multiline-select';
import { registerTypeOptions } from '../../../template-selector/options';
import { useManuReprocessingHandler } from './useManualReprocessingHandler';
import { ConfirmNoteReprocessDialog } from './confirm-note-reprocess-dialog';
import { TServiceMedicalNotes } from '@/app/modules/player/hooks/useConsultationHandler/useCreateMedicalNote';
import { TemplateMap } from '@/app/modules/template-selector/templateSlice';
import { useIsTestingUser } from '@/app/helpers/hooks/useIsTestingUser';

const MINIMUM_DURATION_IN_SECONDS = 30;

type TFullModal = {
  isOpen: boolean;
  isEditing: boolean;
  isSaving: boolean;
  handleClose: () => void;
  onClickCopy: () => void;
  onClickFeedback: (evaluation: TEvaluation) => void;
  onClickEdit: () => void;
  onClickSave: () => void;
  children: React.ReactNode;
  note: TServiceMedicalNotes | undefined;
};

export const FullNoteModal = ({
  children,
  isOpen,
  isEditing,
  isSaving,
  handleClose,
  onClickCopy,
  onClickFeedback,
  onClickEdit,
  onClickSave,
  note,
}: TFullModal) => {
  const [copied, setCopied] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState<'yes' | 'no' | null>(
    null
  );

  const handleCopy = () => {
    onClickCopy();
    setCopied(true);
  };

  const handleFeedback = (evaluation: TEvaluation) => {
    onClickFeedback(evaluation);
    setIsFeedbackSent(evaluation);
  };

  const isMobile = useIsMobile();

  const {
    handleTemplateChange,
    onClickModifyTemplate,
    currentTemplate,
    isReprocessing,
  } = useManuReprocessingHandler({ note });

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const handleOpenConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirm = () => {
    onClickModifyTemplate();
    setIsConfirmDialogOpen(false);
  };
  const isTestingUser = useIsTestingUser();

  return (
    <S.Dialog
      open={isOpen}
      fullScreen={isMobile}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <S.DialogContent>
        <S.DialogHeader>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </S.DialogHeader>
        {isReprocessing ? (
          <Box
            sx={{
              minHeight: '40vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
            <Typography variant="body1" mt="16px">
              Alterando nota...
            </Typography>
          </Box>
        ) : (
          <Box>
            <Box mt="-32px">
              <Typography variant="h6" pb={'8px'}>
                {note?.note_template
                  ? `Nota - ${TemplateMap[note?.note_template]}`
                  : 'Nota'}
              </Typography>
              <Divider />
            </Box>
            <Box>
              <S.ChildrenContent>{children}</S.ChildrenContent>
            </Box>
            <DialogActions>
              <Box sx={{ width: { xs: '60%', md: '30%' } }}>
                {isEditing ? (
                  <Button
                    fullWidth
                    disabled={isSaving}
                    variant="contained"
                    onClick={onClickSave}
                    startIcon={<SaveIcon fontSize="small" />}
                    size="small"
                    color="secondary"
                  >
                    Salvar nota
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    fullWidth
                    onClick={onClickEdit}
                    color="secondary"
                    startIcon={<EditIcon fontSize="small" />}
                  >
                    Editar nota
                  </Button>
                )}
              </Box>
              <Box width="80%">
                <Button
                  variant="contained"
                  color={copied ? 'success' : 'secondary'}
                  fullWidth
                  onClick={handleCopy}
                  autoFocus
                  disabled={isEditing}
                  startIcon={<CopyIcon fontSize="small" />}
                  size="small"
                >
                  {copied ? 'copiado' : 'copiar nota'}
                </Button>
              </Box>
            </DialogActions>
          </Box>
        )}

        <Box mt="16px">
          {Number(note?.duration) > MINIMUM_DURATION_IN_SECONDS && (
            <>
              <Divider />
              <Typography variant="body1" fontWeight="bold" mt="16px">
                Alterar modelo de nota
              </Typography>
              <Typography mb="16px">
                Selecione para qual modelo mudar
              </Typography>
              <MultilineSelect
                width={isMobile ? undefined : 550}
                label="Tipo de registro"
                options={registerTypeOptions}
                value={currentTemplate}
                onChange={handleTemplateChange}
                hideIcon
              />
              <Box mt="8px">
                <Button
                  fullWidth
                  autoFocus
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenConfirmDialog}
                  size="large"
                  disabled={!currentTemplate || isReprocessing}
                  startIcon={
                    <RenewIcon
                      fontSize="small"
                      sx={
                        isReprocessing
                          ? {
                              animation: 'spin 2s linear infinite',
                              '@keyframes spin': {
                                '0%': {
                                  transform: 'rotate(0deg)',
                                },
                                '100%': {
                                  transform: 'rotate(360deg)',
                                },
                              },
                            }
                          : {}
                      }
                    />
                  }
                >
                  {isReprocessing ? 'Alterando nota...' : 'Alterar nota'}
                </Button>
              </Box>
            </>
          )}
        </Box>
        {isTestingUser && (
          <Box mt="16px">
            <Divider />
            <Box display="flex" alignItems="center" mt="16px">
              <Box mr="8px">
                <Typography variant="body2">Essa nota foi útil?</Typography>
              </Box>
              <Box display="flex">
                <S.EvaluationButton
                  disabled={isFeedbackSent !== null}
                  startIcon={<S.ThumbUpIcon />}
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleFeedback(TEvaluation.Yes)}
                  sx={isFeedbackSent === 'yes' ? disabledButton('success') : {}}
                >
                  sim
                </S.EvaluationButton>
                <S.EvaluationButton
                  disabled={isFeedbackSent !== null}
                  startIcon={<S.ThumbDownIcon />}
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleFeedback(TEvaluation.No)}
                  sx={isFeedbackSent === 'no' ? disabledButton('error') : {}}
                >
                  não
                </S.EvaluationButton>
              </Box>
            </Box>
          </Box>
        )}
      </S.DialogContent>
      <ConfirmNoteReprocessDialog
        isOpen={isConfirmDialogOpen}
        onClickCancel={handleCloseConfirmDialog}
        onClickConfirm={handleConfirm}
      />
    </S.Dialog>
  );
};

const disabledButton = (color: string) => ({
  '&.Mui-disabled': {
    background: `${color}.main`,
    color: `${color}.light`,
  },
});
