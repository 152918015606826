import { Box } from '@mui/material';
import { HeaderLogo } from './components/header-logo';
import { LoginButton } from './components/login-button';

export const AppHeader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: { xs: '25px', sm: '45px' },
        padding: '0 25px',
      }}
    >
      <Box sx={{ margin: 'auto' }}>
        <HeaderLogo />
      </Box>
      <Box>
        <LoginButton />
      </Box>
    </Box>
  );
};
