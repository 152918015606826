import { Alert, AlertTitle, styled, Box, Slide, Backdrop } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/app/app-redux/store';
import { addError } from './errorHandlerSlice';

type TErrorHandlerProvider = {
  children: React.ReactNode;
};
export const ErrorHandlerProvider = (props: TErrorHandlerProvider) => {
  const { isOpen, withBackdrop, errors } = useAppSelector(
    (state) => state.errorHandler
  );
  const dispatch = useAppDispatch();

  const Wrapper = withBackdrop ? Backdrop : (Box as typeof Backdrop);

  const handleClose = () => {
    dispatch(
      addError({
        errors: [],
        isOpen: false,
      })
    );
  };

  return (
    <>
      <Wrapper
        open={isOpen}
        onClick={handleClose}
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      >
        <>
          {errors.map((error, index) => (
            <Slide
              key={index}
              direction="down"
              in={isOpen}
              mountOnEnter
              unmountOnExit
            >
              <ErrorBox key={index}>
                <Alert
                  severity={error.severity || 'error'}
                  onClose={handleClose}
                >
                  <AlertTitle>{error.title}</AlertTitle>
                  {error.message}
                </Alert>
              </ErrorBox>
            </Slide>
          ))}
        </>
      </Wrapper>
      {props.children}
    </>
  );
};

const ErrorBox = styled(Box)`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.modal + 1};
  right: 20px;
  top: 30px;
  max-width: 485px;
`;
