import { ReactNode } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useIsMobile } from '@/app/helpers/hooks/useIsMobile';

type TSelectionModal = {
  label: string;
  optionRef?: React.RefObject<HTMLLIElement>;
  value: string;
  description: string;
  noteExampleComponent: () => ReactNode;
  onClose: (optionRef?: React.RefObject<HTMLLIElement>) => void;
  onSelect: (label: string, optionRef?: React.RefObject<HTMLLIElement>) => void;
  isOpen: boolean;
};

export const SelectionModal = (props: TSelectionModal) => {
  const isMobile = useIsMobile();
  const NoteExampleComponent = props.noteExampleComponent || (() => null);

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onClose(props.optionRef)}
      fullWidth
      maxWidth="xs"
      fullScreen={isMobile}
    >
      <Box position="absolute" right="24px" top="15px">
        <IconButton onClick={() => props.onClose(props.optionRef)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box width="90%">
        <DialogTitle>{props.label}</DialogTitle>
      </Box>
      <Divider />
      <DialogContent>
        <Box mb="16px">
          <Typography component="div" variant="body1">
            <div dangerouslySetInnerHTML={{ __html: props.description }} />
          </Typography>
        </Box>
        <Box width="90%" margin="auto">
          <NoteExampleComponent />
        </Box>
      </DialogContent>
      <Divider />
      <Box pt="8px" pb="16px" px="22px">
        <DialogActions>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => props.onClose(props.optionRef)}
          >
            Voltar
          </Button>
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => props.onSelect(props.value, props.optionRef)}
          >
            Selecionar nota
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
