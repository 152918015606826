import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)`
  max-width: 400px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(4)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px;
    width: 100%;
  }
`;
