import { ChangeEvent } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from '@mui/material';

import { CustomDialog } from '@/app/components/custom-dialog';

import logo from '@/assets/logo.png';
import { TextFieldPassword } from '../text-field-password';

import { applyMask } from './signup-helpers';
import { useSignupForm } from './useSignupForm';
import { terms } from '@/app/routes';

export const SignupModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { onSubmit, setValue, register, formErrors } = useSignupForm();

  const authParam = searchParams.get('auth');
  const isModalOpen = authParam === 'signup';

  const openSignUp: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setSearchParams({ auth: 'login' });
  };

  const handlePhoneChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    const maskedValue = applyMask(value);

    setValue('phone', maskedValue);
  };

  return (
    <CustomDialog
      withBackbutton
      open={isModalOpen}
      onClose={() => setSearchParams({ auth: '' })}
    >
      <Box>
        <Box sx={{ marginBottom: '48px', textAlign: 'center' }}>
          <img src={logo} alt="Logo" width="141px" />
        </Box>
        <Box sx={{ marginBottom: '32px', textAlign: 'center' }}>
          <Typography variant="h6">Cadastre-se</Typography>
          <Typography variant="body2">
            Já tem conta?{' '}
            <Link underline="hover" onClick={openSignUp}>
              Entrar
            </Link>
          </Typography>
        </Box>
        <Box>
          <form onSubmit={onSubmit}>
            <Box mb={(theme) => theme.spacing(1)}>
              <TextField
                fullWidth
                {...register('name')}
                placeholder="Nome completo"
                error={Boolean(formErrors.name?.message)}
                helperText={formErrors.name?.message}
              />
            </Box>
            <Box mb={(theme) => theme.spacing(1)}>
              <TextField
                fullWidth
                {...register('email')}
                placeholder="E-mail"
                error={Boolean(formErrors.email?.message)}
                helperText={formErrors.email?.message}
              />
            </Box>
            <Box mb={(theme) => theme.spacing(1)}>
              <TextFieldPassword
                fullWidth
                {...register('password')}
                placeholder="Senha"
                error={Boolean(formErrors.password?.message)}
                helperText={formErrors.password?.message}
              />
            </Box>
            <Box mb={(theme) => theme.spacing(1)}>
              <TextFieldPassword
                fullWidth
                {...register('confirmPassword')}
                placeholder="Confirmar senha"
                error={Boolean(formErrors.confirmPassword?.message)}
                helperText={formErrors.confirmPassword?.message}
              />
            </Box>
            <Box mb={(theme) => theme.spacing(1)}>
              <TextField
                fullWidth
                {...register('phone')}
                placeholder="Telefone"
                onChange={handlePhoneChange}
                error={Boolean(formErrors.phone?.message)}
                helperText={formErrors.phone?.message}
              />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl error={Boolean(formErrors?.terms?.message)}>
                  <FormControlLabel
                    control={<Checkbox {...register('terms')} />}
                    label={
                      <Typography variant="body2">
                        Concordo com os{' '}
                        <LinkRouter
                          to={terms}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          termos e política de privacidade
                        </LinkRouter>
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormHelperText>{formErrors.terms?.message}</FormHelperText>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ marginTop: (theme) => theme.spacing(4) }}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                fullWidth
                color="secondary"
              >
                Cadastrar
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </CustomDialog>
  );
};
