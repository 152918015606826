import { Box, Typography } from '@mui/material';

export type TSelectionModalTopics = {
  title: string;
  description: string;
};

type TDefaultReportExampleProps = {
  description: string;
};
export const DefaultReportExample = ({
  description,
}: TDefaultReportExampleProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#e5e5e5',
        padding: '22px',
        borderRadius: '12px',
      }}
    >
      <Box mb="16px">
        <Typography variant="body1" fontWeight="bold">
          Relato de consulta
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  );
};
