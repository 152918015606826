import { Box, IconButton, styled } from '@mui/material';
import Mic from '@mui/icons-material/Mic';
import PauseIconSVG from '@mui/icons-material/PauseCircleOutlined';
import PlayIconSVG from '@mui/icons-material/PlayCircleOutline';
import StopIconSVG from '@mui/icons-material/StopCircleOutlined';

export const RecordButton = styled(IconButton)`
  width: 84px;
  height: 84px;
  background-color: rgba(217, 217, 217, 0.2);
`;

export const PauseButton = styled(IconButton)``;
export const PlayButton = styled(IconButton)``;

export const PauseIcon = styled(PauseIconSVG)`
  font-size: 48px;
`;

export const PlayIcon = styled(PlayIconSVG)`
  font-size: 48px;
`;

export const StopButton = styled(IconButton)``;

export const StopIcon = styled(StopIconSVG)`
  font-size: 48px;
`;

export const MicIcon = styled(Mic)`
  font-size: 62px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Timer = styled(Box)`
  display: flex;
  justify-content: center;
  font-size: 90px;
  font-weight: 100;
  letter-spacing: 0;
  color: #717171;
`;

const TimeStyle = styled(Box)`
  color: #717171;
  font-weight: 400;
`;
export const Hours = styled(TimeStyle)``;
export const Minutes = styled(TimeStyle)``;
export const Seconds = styled(TimeStyle)``;
