import { useEffect, useState } from 'react';
import './infra/amplitude/amplitude-config';
import './infra/firebase/firebase-config';
import { RouterProvider } from 'react-router-dom';

import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import { Box, CircularProgress } from '@mui/material';
import { router } from './router';

export function App() {
  const [isAppReady, setIsAppReady] = useState(false);
  useEffect(() => {
    const remoteConfig = getRemoteConfig();
    fetchAndActivate(remoteConfig).finally(() => {
      console.log('Remote config fetched');
      setIsAppReady(true);
    });
  }, []);
  return isAppReady ? (
    <RouterProvider router={router} />
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  );
}

export default App;
