import { useEffect, useRef, useState } from 'react';
import { StatusMessages } from 'react-media-recorder';
import { useTrackFuncWithDefaultRecordingProps } from '../../../helpers/events/track-event';
import { trackingEvent } from '../../../helpers/events/events';

type TUseStatus = {
  status: StatusMessages;
  recordingId: string;
};
export const useStatusTracking = ({ status, recordingId }: TUseStatus) => {
  const [recordingTime, setRecordingTime] = useState<number>(0);

  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  useEffect(() => {
    switch (status) {
      case 'acquiring_media':
        trackFuncWithDefaultRecordingProps(
          trackingEvent['Microphone Authorization Granted']
        );
        break;

      case 'permission_denied':
        trackFuncWithDefaultRecordingProps(
          trackingEvent['Microphone Authorization Denied']
        );
        break;

      case 'recording':
        trackFuncWithDefaultRecordingProps(trackingEvent['Recording Started']);
        break;
      case 'paused':
        trackFuncWithDefaultRecordingProps(trackingEvent['Recording Paused'], {
          at_seconds: recordingTime,
        });
        break;
      case 'stopped':
        trackFuncWithDefaultRecordingProps(trackingEvent['Recording Stopped'], {
          total_time: recordingTime,
        });
        break;

      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const interval = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (status === 'recording') {
      interval.current = setInterval(() => {
        setRecordingTime((previousTime) => previousTime + 1);
      }, 1000);
    } else {
      clearInterval(interval.current);
    }
  }, [status]);
};
