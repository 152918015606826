import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';
import { trackingEvent } from '@/app/helpers/events/events';

import { TCurrentTemplate } from '@/app/modules/template-selector/templateSlice';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '@/app/helpers/services';
import { openNote } from '@/app/modules/full-note/noteSlice';
import { useAppDispatch } from '@/app/app-redux/store';
import { addError } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';
import { errors } from '@/app/helpers/errors';
import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { AxiosError } from 'axios';
import {
  TServiceMedicalNotes,
  useCreateMedicalNote,
} from '../../../player/hooks/useConsultationHandler/useCreateMedicalNote';

type TUseManualReprocessingHandler = {
  note?: TServiceMedicalNotes;
};

export const useManuReprocessingHandler = ({
  note,
}: TUseManualReprocessingHandler) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const { createMedicalNote } = useCreateMedicalNote();

  const [isReprocessing, setIsReprocessing] = useState(false);

  const deleteMedicalNote = useMutation({
    retry: false,
    mutationFn: ({ noteId }: { noteId: string }) =>
      axiosInstance.delete<string>(`v2/medical_notes/${noteId}`),
  });

  const [currentTemplate, setCurrentTemplate] = useState('');

  const handleTemplateChange = (event: SelectChangeEvent) => {
    trackFuncWithDefaultRecordingProps(trackingEvent['Specialty Selected'], {
      specialty: event.target.value,
      reprocessing: true,
    });
    setCurrentTemplate(event.target.value as string);
  };

  const onClickModifyTemplate = async () => {
    try {
      setIsReprocessing(true);
      const newMedicalNote = await createMedicalNote({
        consultationId: note?.consultation_id || '',
        template: currentTemplate as TCurrentTemplate,
        transcriptionId: note?.transcription_id || '',
        startedAt: new Date().toISOString(),
        isReprocess: true,
        wasManuallyReprocessed: true,
      });

      if (newMedicalNote.medicalNoteId) {
        dispatch(
          openNote({
            isOpen: true,
            noteId: newMedicalNote.medicalNoteId,
            noteTemplate: newMedicalNote.noteTemplate,
          })
        );
      }

      await deleteMedicalNote.mutateAsync({
        noteId: note?.id || '',
      });
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 422) {
        dispatch(addError({ errors: [errors['audio-002']] }));
      } else {
        dispatch(addError({ errors: [errors.generic] }));
      }
    } finally {
      setIsReprocessing(false);
      queryClient.refetchQueries({ queryKey: ['notes'] });
    }
  };

  return {
    currentTemplate,
    handleTemplateChange,
    onClickModifyTemplate,
    isReprocessing,
  };
};
