import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type Recording = {
  recordingId: string;
};

const initialState = {
  recordingId: '',
};

export const recordingSlice = createSlice({
  name: 'recording',
  initialState,
  reducers: {
    addRecordingId: (state, action: PayloadAction<Recording>) => {
      state.recordingId = action.payload.recordingId;
    },
  },
});

export const { addRecordingId } = recordingSlice.actions;

export default recordingSlice.reducer;
