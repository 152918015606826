import { captureMessage } from '@/app/helpers/logging/logging';
import { useRemoteConfig } from '@/app/infra/firebase/useRemoteConfig';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { useEffect } from 'react';

export const useFirebaseAudioUpload = (audioBlob: Blob | null) => {
  const { data: isFirebaseUploadEnabled } = useRemoteConfig({
    name: 'firebase_upload_audio_enabled',
    type: 'boolean',
  });

  useEffect(() => {
    if (isFirebaseUploadEnabled && audioBlob) {
      const storage = getStorage();
      const storageRef = ref(storage, `${new Date().toISOString()}-audio.mp3`);
      uploadBytes(storageRef, audioBlob)
        .then((snapshot) => {
          console.log('Uploaded FB!');
        })
        .catch((error) => {
          console.log({ error });
          captureMessage(error);
        });
    }
  }, [audioBlob, isFirebaseUploadEnabled]);
};
