import * as Sentry from '@sentry/browser';
import { getRemoteConfigFlag } from '../../infra/firebase/remote-config';

export const captureMessage: typeof Sentry.captureMessage = (
  message,
  context
) => {
  const isLoggingEnable = getRemoteConfigFlag({
    name: 'logging',
    type: 'boolean',
  });
  if (!isLoggingEnable) return '';

  return Sentry.captureMessage(message, context);
};

export const captureException: typeof Sentry.captureException = (
  exception: unknown,
  hint
) => {
  const isLoggingEnable = getRemoteConfigFlag({
    name: 'logging',
    type: 'boolean',
  });
  if (!isLoggingEnable) return '';

  return Sentry.captureException(exception, hint);
};
