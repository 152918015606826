import { TemplateSelector } from '../../modules/template-selector';
import { Box, Typography } from '@mui/material';
import { Player } from '../../modules/player';

export const Recording = () => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '650px',
        margin: 'auto',
      }}
    >
      <Box>
        <Typography variant="subtitle1" textAlign="center" color="primary">
          Geramos evoluções clínicas
        </Typography>
        <Typography variant="subtitle1" textAlign="center" color="secondary">
          para seu prontuário
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TemplateSelector />
      </Box>
      <Box>
        <Player />
      </Box>
    </Box>
  );
};
