import { Box, Typography } from '@mui/material';

export type TSelectionModalTopics = {
  title: string;
  description: string;
};

type TDefaultMedicalNoteExampleProps = {
  topics: TSelectionModalTopics[];
};

export const DefaultMedicalNoteExample = ({
  topics,
}: TDefaultMedicalNoteExampleProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#e5e5e5',
        padding: '22px',
        borderRadius: '12px',
      }}
    >
      <Box mb="16px">
        <Typography variant="body1" fontWeight="bold">
          Nota médica
        </Typography>
      </Box>
      {topics.map((topic, index) => (
        <Box key={index} mb="8px">
          <Typography fontWeight={900} variant="body2">
            {topic.title}
          </Typography>
          <ul
            style={{
              marginTop: 0,
              paddingTop: 0,
              marginLeft: '12px',
              paddingLeft: '12px',
            }}
          >
            <li>
              <Typography variant="body2">{topic.description}</Typography>
            </li>
          </ul>
        </Box>
      ))}
    </Box>
  );
};
