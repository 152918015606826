import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box maxWidth={'680px'}>
      <Typography variant="subtitle2">
        Termos de Uso - copiloto carecode
      </Typography>
      <Box my="32px">
        <Box mb="8px">
          <Typography>
            Bem-vindo ao copiloto carecode, um serviço desenvolvido para
            auxiliar profissionais de saúde na geração de resumos de consultas a
            partir de gravações de áudio. Antes de utilizar nossos serviços,
            leia atentamente estes Termos de Uso.
          </Typography>
        </Box>
        <Box mb="8px" sx={{ li: { marginBottom: '16px' } }}>
          <ol>
            <li>
              <Typography mb="8px">Aceitação dos Termos</Typography>
              Ao acessar ou utilizar o serviço copiloto carecode, você concorda
              com estes Termos de Uso. Caso não concorde com algum termo ou
              condição, recomendamos que interrompa o uso dos nossos serviços.
            </li>
            <li>
              <Typography mb="16px">Uso do Serviço</Typography>
              <ul>
                <li>
                  O copiloto carecode permite que profissionais de saúde gravem
                  consultas em tempo real, capturando áudio tanto do
                  profissional quanto do paciente.
                </li>
                <li>
                  O copiloto carecode permite que profissionais de saúde gravem
                  relatos de consulta ou exames, capturando o áudio do
                  profissional de saúde que esteja relatando o caso.
                </li>
                <li>
                  O áudio da consulta é armazenado pelo período de até 15 dias,
                  após o qual será automaticamente apagado. O profissional tem a
                  opção de apagar o áudio imediatamente a qualquer momento antes
                  desse prazo.
                </li>
                <li>
                  O áudio será transcrito para texto a fim de facilitar o
                  profissional a realizar o registro clínico.
                </li>
                <li>
                  A empresa carecode não utiliza o áudio para qualquer
                  finalidade além da geração de resumos para facilitar o
                  trabalho do profissional. Não realizamos qualquer outra
                  análise adicional dos áudios gravados além das transcrições.
                </li>
                <li>
                  O histórico de resumos gerados e as transcrições dos áudios em
                  formato texto serão mantidos pela carecode para referência
                  futura do profissional. Esse dados são mantido criptografados.
                </li>
                <li>
                  A empresa carecode pode extrair do histórico de resumos
                  informações agregadas e anonimizadas, tais como as principais
                  doenças sendo tratadas, exames sendo recomendados,
                  medicamentos sendo receitados, etc.
                </li>
              </ul>
            </li>
            <li>
              <Typography mb="16px">
                Responsabilidade do profissional de saúde
              </Typography>
              <ul>
                <li>
                  O profissional de saúde é responsável por informar ao paciente
                  sobre a gravação da consulta e o armazenamento dos dados,
                  quando considerar pertinente, assegurando o consentimento
                  informado
                </li>
                <li>
                  Cabe ao profissional de saúde revisar e, se necessário, editar
                  os resumos gerados pelo copiloto carecode antes de incluí-los
                  nos prontuários médicos.
                </li>
              </ul>
            </li>
            <li>
              <Typography mb="16px">Privacidade e Segurança</Typography>
              <ul>
                <li>
                  A carecode adota medidas rigorosas para garantir a segurança e
                  privacidade dos dados. O acesso aos registros é restrito a
                  profissionais autorizados, e implementamos práticas de
                  segurança de acordo com as diretrizes da Lei Geral de Proteção
                  de Dados (LGPD).
                </li>
                <li>
                  Não compartilhamos informações pessoais dos usuários com
                  terceiros, exceto quando exigido por lei ou autorizado pelo
                  usuário.
                </li>
              </ul>
            </li>
            <li>
              <Typography mb="16px">Limitação de Responsabilidade</Typography>
              <ul>
                <li>
                  A carecode não se responsabiliza por eventuais perdas de
                  dados, danos ou consequências decorrentes do uso do serviço.
                </li>
                <li>
                  Recomendamos que o profissional de saúde mantenha cópias de
                  segurança dos resumos importantes em seus próprios sistemas.
                </li>
              </ul>
            </li>
            <li>
              <Typography mb="16px">Modificações nos Termos de Uso</Typography>
              <ul>
                <li>
                  A carecode se reserva o direito de modificar estes Termos de
                  Uso a qualquer momento. Alterações serão comunicadas aos
                  usuários e entrarão em vigor após publicação.
                </li>
              </ul>
            </li>
            <li>
              <Typography mb="16px">Contato</Typography>
              <ul>
                <li>
                  Para esclarecimentos ou dúvidas sobre estes Termos de Uso,
                  entre em contato conosco através dos canais disponíveis no
                  setor de ajuda do copiloto.
                </li>
              </ul>
            </li>
          </ol>
        </Box>
        <Box mt="16px" sx={{ paddingBottom: { xs: '100%', md: '35%' } }}>
          <Typography>
            Obrigado por escolher a carecode para auxiliar em suas práticas
            médicas.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
