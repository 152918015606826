import {
  Box,
  Dialog as DialogMUI,
  DialogContent as DialogContentMUI,
  styled,
  Button,
} from '@mui/material';
import ThumbUp from '@mui/icons-material/ThumbUpOutlined';
import ThumbDown from '@mui/icons-material/ThumbDownOutlined';

export const ThumbUpIcon = styled(ThumbUp)``;
export const ThumbDownIcon = styled(ThumbDown)``;
export const EvaluationButton = styled(Button)`
  height: 26px;
  margin-right: 8px;
  .MuiButton-startIcon > *:nth-of-type(1) {
    font-size: 14px;
  }
`;

export const Dialog = styled(DialogMUI)``;

export const DialogHeader = styled(Box)`
  display: flex;
  justify-content: end;
  padding: 0 8px;
  border-radius: 8px 8px 0 0;
`;

export const DialogContent = styled(DialogContentMUI)``;

export const ChildrenContent = styled(Box)`
  overflow-y: scroll;
  max-height: 40vh;
  padding-right: ${({ theme }) => theme.spacing(2)};
  height: 100%;
  white-space: pre-wrap;
  margin-top: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-height: 60vh;
    padding-right: 0;
  }
`;
