import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';

import { StatusMessages } from 'react-media-recorder';

import * as S from './styles';
import { useRecordingTime } from '../../hooks/useRecordingTime';
import { useIsMobile } from '@/app/helpers/hooks/useIsMobile';
import { ProcessingLabel } from '../../hooks/useConsultationHandler/useConsultationAsyncProcess';

export enum TInternalStatus {
  'uninitialized' = 'uninitialized',
  'preparing' = 'preparing',
  'ready' = 'ready',
  'unauthorized' = 'unauthorized',
}

type TRecordingControl = {
  internalStatus: TInternalStatus;
  handleStartRecording: () => void;
  handleStopRecording: () => void;
  handleTogglePause: () => void;
  status: StatusMessages;
  disabled?: boolean;
  isProcessing: boolean;
  processingLabel: ProcessingLabel | null;
};

enum RecordControlRenderState {
  'readyToRecord' = 'readyToRecord',
  'recording' = 'recording',
  'processing' = 'processing',
}

export const RecordingControl = (props: TRecordingControl) => {
  const handleToggleRecording = () => {
    props.handleStartRecording();
  };

  const isRecording =
    props.status === 'recording' ||
    props.status === 'acquiring_media' ||
    props.status === 'paused';

  const renderStateMachine = () => {
    if (isRecording) return RecordControlRenderState.recording;
    if (!isRecording && !props.isProcessing)
      return RecordControlRenderState.readyToRecord;
    if (!isRecording && props.isProcessing)
      return RecordControlRenderState.processing;
    else return RecordControlRenderState.readyToRecord;
  };
  const renderStatus: RecordControlRenderState = renderStateMachine();

  const isMobile = useIsMobile();

  return (
    <Box mt={(theme) => theme.spacing(4)}>
      {renderStatus === RecordControlRenderState.readyToRecord && (
        <Box
          display="inline-block"
          sx={{
            cursor: 'pointer',
          }}
        >
          <Tooltip
            arrow
            open={props.internalStatus === 'unauthorized'}
            onOpen={() => {}}
            onClose={() => {}}
            title={
              props.internalStatus === 'unauthorized'
                ? 'por favor, conceda autorização ao microfone do dispositivo para continuar'
                : ''
            }
            placement={isMobile ? 'top' : 'right'}
          >
            <Box>
              <S.RecordButton
                onClick={handleToggleRecording}
                aria-label="microfone"
                disabled={props.internalStatus === 'unauthorized'}
              >
                <S.MicIcon />
              </S.RecordButton>
            </Box>
          </Tooltip>
          <Box>
            <Typography variant="body1">Iniciar gravação</Typography>
          </Box>
        </Box>
      )}

      {renderStatus === RecordControlRenderState.recording && (
        <Timer
          status={props.status}
          handleTogglePause={props.handleTogglePause}
          handleStopRecording={props.handleStopRecording}
        />
      )}

      {renderStatus === RecordControlRenderState.processing && (
        <Box maxWidth={'600px'} margin="auto" textAlign={'center'}>
          <Box mb="8px">
            <CircularProgress size="32px" />
          </Box>
          <Typography variant="body2">{props.processingLabel}</Typography>
        </Box>
      )}
    </Box>
  );
};

type TStatusProps = {
  status: StatusMessages;
  handleTogglePause: () => void;
  handleStopRecording: () => void;
};
const Timer = (props: TStatusProps) => {
  const {
    minutes,
    seconds = 0,
    hours,
  } = useRecordingTime({ status: props.status });

  return (
    <>
      <S.Timer>
        <S.Hours>{hours}</S.Hours>:<S.Minutes>{minutes}</S.Minutes>:
        <S.Seconds>{seconds}</S.Seconds>
      </S.Timer>
      <Box mt="-24px">
        <Typography variant="h6" fontWeight={400}>
          gravando...
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt="40px">
        <Box mr="32px" onClick={props.handleTogglePause}>
          {props.status === 'paused' ? (
            <>
              <S.PlayButton>
                <S.PlayIcon />
              </S.PlayButton>
              <Typography variant="h6" color="#717171">
                retomar
              </Typography>
            </>
          ) : (
            <>
              <S.PauseButton>
                <S.PauseIcon />
              </S.PauseButton>
              <Typography variant="h6" color="#717171">
                pausar
              </Typography>
            </>
          )}
        </Box>
        <Box onClick={props.handleStopRecording}>
          <S.StopButton>
            <S.StopIcon />
          </S.StopButton>
          <Typography variant="h6" color="#717171">
            finalizar
          </Typography>
        </Box>
      </Box>
    </>
  );
};
