import { Box } from '@mui/material';
import * as S from './styles';
import { useRecordingHandlers } from './hooks/useRecordingHandlers';
import { RecordingControl } from './components/recording-control';

import { FullNote } from '../full-note';
import { Tutorial } from './components/tutorial';
import { Placement } from 'react-joyride';

export const Player = () => {
  const {
    handleStopRecording,
    handleStartRecording,
    handleTogglePause,
    internalStatus,
    isProcessing,
    processingLabel,
    status,
    runTutorial,
    setRunTutorial,
  } = useRecordingHandlers();

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ marginTop: { xs: '0', md: (theme) => theme.spacing(8) } }}
    >
      <Tutorial
        runTutorial={runTutorial}
        setRunTutorial={setRunTutorial}
        steps={[
          {
            target: '.required-selects',
            content: 'Selecione o Tipo de registro para iniciar a gravação',
            disableBeacon: true,
            placement: 'top' as Placement,
          },
        ]}
      />
      <Box maxWidth="450px">
        <S.RecordButtonWrapper>
          <RecordingControl
            handleStartRecording={handleStartRecording}
            handleStopRecording={handleStopRecording}
            handleTogglePause={handleTogglePause}
            status={status}
            internalStatus={internalStatus}
            isProcessing={isProcessing}
            processingLabel={processingLabel}
          />
        </S.RecordButtonWrapper>
      </Box>
      <FullNote />
    </Box>
  );
};
