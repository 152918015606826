import { getAuth } from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { useMutation } from '@tanstack/react-query';
import { addAuthHeader, axiosInstance } from '@/app/helpers/services';

import {
  captureException,
  captureMessage,
} from '@/app/helpers/logging/logging';

import { AxiosError } from 'axios';
import { errors } from '@/app/helpers/errors';
import { addError } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';
import { useAppDispatch } from '@/app/app-redux/store';

export const useProcessAudio = (audioBlob: Blob | null) => {
  const dispatch = useAppDispatch();

  const consultationMutation = useMutation({
    mutationFn: () =>
      axiosInstance.post<TServiceConsultation>('v2/consultations'),
  });

  const getSignedUrlMutation = useMutation({
    mutationFn: () =>
      axiosInstance.post<TServiceGetSignerUrl>('v2/transcriptions/signed_url'),
  });

  const signedUrlMutation = useMutation({
    mutationFn: ({ signedUrl, currentBlob }: TServiceSignedUrlVariables) => {
      return fetch(signedUrl, {
        headers: {
          'Content-Type': 'audio/mp3',
        },
        method: 'PUT',
        body: currentBlob,
      });
    },
  });

  const transcriptionMutation = useMutation({
    mutationFn: ({ audioUrl }: TServiceTranscriptionVariables) =>
      axiosInstance.post<TServiceTranscription>('v2/transcriptions', {
        signed_url: audioUrl,
      }),
  });

  const processAudio = async (): Promise<TProcessAudioReturn> => {
    if (!audioBlob) {
      throw new Error('No audio blob');
    }

    try {
      Sentry.addBreadcrumb({
        category: 'custom',
        message: JSON.stringify({
          size: audioBlob.size,
          type: audioBlob.type,
        }),
        level: 'info',
      });
      // Force refresh token in case the last getToken was more than 1 hour ago
      // For example if the user is recording a long audio or if the page was open for a long time
      const auth = getAuth();
      const refreshedToken = await auth.currentUser?.getIdToken(true);
      if (refreshedToken) addAuthHeader(refreshedToken);

      const {
        data: { id: consultationId = '' },
      } = await consultationMutation.mutateAsync();

      captureMessage('Iniciando signed_url');
      const { data: signedUrlData } = await getSignedUrlMutation.mutateAsync();

      captureMessage('Iniciando consulta na signed_url');

      const audioUrlData = await signedUrlMutation.mutateAsync({
        signedUrl: signedUrlData.signed_url || '',
        currentBlob: audioBlob,
      });

      const {
        data: { id: transcriptionId },
      } = await transcriptionMutation.mutateAsync({
        audioUrl: audioUrlData.url,
      });

      return {
        consultationId,
        transcriptionId,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error);
        throw error;
      } else {
        dispatch(addError({ errors: [errors.generic] }));
      }

      captureException(error);
      throw new Error('Error on process audio');
    }
  };

  return {
    processAudio,
  };
};

export type TProcessAudioReturn = {
  consultationId: string;
  transcriptionId: string;
};

type TServiceConsultation = {
  id?: string;
};

type TServiceGetSignerUrl = {
  signed_url?: string;
};

type TServiceSignedUrlVariables = {
  signedUrl: string;
  currentBlob: Blob;
};

type TServiceTranscriptionVariables = {
  audioUrl?: string;
};

type TServiceTranscription = {
  id: string;
};
