import { AlertProps } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type TErrorSeverity = AlertProps['severity'];

export type TErrors = {
  severity?: TErrorSeverity;
  code?: string;
  title: string;
  message: string;
};

const initialState = {
  errors: [] as TErrors[],
  isOpen: false,
  withBackdrop: false,
};

export const errorHandlerSlice = createSlice({
  name: 'errorHandler',
  initialState,
  reducers: {
    addError: (
      state,
      action: PayloadAction<{
        errors: TErrors[];
        isOpen?: boolean;
        withBackdrop?: boolean;
      }>
    ) => {
      const { errors, isOpen = true, withBackdrop = false } = action.payload;
      state.errors = errors.length ? errors : state.errors;
      state.isOpen = isOpen;
      state.withBackdrop = withBackdrop;
    },
    removeErrors: (state) => {
      state.errors = [];
      state.isOpen = false;
      state.withBackdrop = false;
    },
  },
});

export const { addError, removeErrors } = errorHandlerSlice.actions;

export default errorHandlerSlice.reducer;
