import { useMutation } from '@tanstack/react-query';
import { User, deleteUser } from 'firebase/auth';
import { axiosInstance } from '@/app/helpers/services';
import { TSignupFormInputs } from '../components/signup-modal/useSignupForm';

type TSignupLocal = {
  phone_number: string;
  fullname: string;
  firebase_user_id: string;
  user_auth_id: string;
};

type TCreateLocalUser = {
  user: User;
  values: TSignupFormInputs;
  localUserId?: string;
};

export const useCreateLocalUser = () => {
  const signupLocalMutation = useMutation({
    mutationFn: (payload: TSignupLocal) =>
      axiosInstance.post(`/auth/signup/v2`, payload),
  });

  const deleteFirebaseUserMutation = useMutation({
    mutationFn: (user: User) => deleteUser(user),
  });

  const createLocalUser = async ({
    user,
    values,
    localUserId,
  }: TCreateLocalUser) => {
    try {
      await signupLocalMutation.mutateAsync({
        phone_number: `+55${values.phone}`,
        fullname: values.name,
        firebase_user_id: user.uid,
        user_auth_id: localUserId || '',
      });
    } catch (error) {
      deleteFirebaseUserMutation.mutateAsync(user);
      throw error;
    }
  };

  return { createLocalUser };
};
