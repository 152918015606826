import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from '@/app/app-redux/store';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { useCreateLocalUser } from '../../hooks/useCreateLocalUser';
import { useSearchParams } from 'react-router-dom';
import { dispatchFirebaseError } from '../../helpers';
import { FirebaseError } from 'firebase/app';
import { addError } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';
import { TLocalHTTPValidationError } from '@/app/helpers/services';
import { AxiosError } from 'axios';
import { errors } from '@/app/helpers/errors';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';
import { trackingEvent } from '@/app/helpers/events/events';

// At least two words with at least 2 letters on the first and 1 letter on the second
const isValidFullNameRegex = /^[A-Za-z]{2,}(?:\s[A-Za-z]{1,})+$/;

export const isValidEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup
  .object({
    name: yup
      .string()
      .matches(
        isValidFullNameRegex,
        'Por favor, preencha um nome completo válido'
      )
      .required('Por favor, preencha um nome completo válido'),
    email: yup
      .string()
      .matches(isValidEmailRegex, 'Por favor, preencha um e-mail válido')
      .required('Por favor, preencha um e-mail'),
    password: yup
      .string()
      .required('Por favor, preencha uma senha')
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*()&])[A-Za-z0-9!@#$%^&*()&]{6,}$/,
        'A senha deve conter pelo menos 6 caracteres, incluindo 1 caractere especial e pelo menos 1 número'
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'As senhas devem ser iguais')
      .required('Por favor, confirme a sua senha'),
    phone: yup.string().required('Por favor, preencha um telefone'),
    terms: yup
      .boolean()
      .oneOf([true], 'Você deve aceitar os termos de uso')
      .required('Você deve aceitar os termos de uso'),
  })
  .required();

export type TSignupFormInputs = {
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  name: string;
  terms: boolean;
};

export const useSignupForm = () => {
  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const dispatch = useAppDispatch();
  const auth = getAuth();
  const [, setSearchParams] = useSearchParams();

  const { createLocalUser } = useCreateLocalUser();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setValue,
  } = useForm<TSignupFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<TSignupFormInputs> = async (data) => {
    const values = {
      ...data,
      phone: data.phone.replace(/\D/g, ''),
    };

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      trackFuncWithDefaultRecordingProps(trackingEvent['User Registered'], {
        success: true,
      });
      const user = userCredential.user;
      const localUserId = localStorage.getItem('user_id') || '';

      await createLocalUser({ user, values, localUserId });
      setSearchParams({ auth: '' });
    } catch (error) {
      if (error instanceof FirebaseError) {
        dispatchFirebaseError(dispatch)(error);
        trackFuncWithDefaultRecordingProps(trackingEvent['User Registered'], {
          success: false,
          error: error?.code || '',
        });
        return;
      }
      if (error instanceof AxiosError) {
        const axiosError = error as TLocalHTTPValidationError;
        if (axiosError.response?.data?.detail) {
          axiosError.response.data.detail.forEach((detail) => {
            if (detail.loc.includes('phone_number')) {
              dispatch(addError({ errors: [errors['account-creation-001']] }));
            }
          });
          return;
        }
      }
    }
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    handleSubmit,
    setValue,
    formErrors,
  };
};
