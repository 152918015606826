export const VITE_FIREBASE_API_KEY =
  import.meta.env.VITE_FIREBASE_API_KEY || '';
export const VITE_AMPLITUDE_API_KEY =
  import.meta.env.VITE_AMPLITUDE_API_KEY || '';
export const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const VITE_FIREBASE_DOMAIN = import.meta.env.VITE_FIREBASE_DOMAIN || '';
export const VITE_FIREBASE_MESSAGING_SENDER_ID =
  import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || '';
export const VITE_FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID || '';
export const VITE_FIREBASE_MEASUREMENT_ID =
  import.meta.env.VITE_FIREBASE_MEASUREMENT_ID || '';
