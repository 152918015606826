import { CustomDialog } from '@/app/components/custom-dialog';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';

type TConfirmNoteReprocessDialog = {
  onClickConfirm: () => void;
  onClickCancel: () => void;
  isOpen: boolean;
};

export const ConfirmNoteReprocessDialog = ({
  onClickConfirm,
  onClickCancel,
  isOpen,
}: TConfirmNoteReprocessDialog) => {
  return (
    <CustomDialog
      onClose={onClickCancel}
      dialogProps={{
        fullScreen: false,
        open: isOpen,
        maxWidth: 'xs',
      }}
    >
      <DialogContent>
        <Box>
          <Typography variant="h6" width="90%">
            Deseja substituir para novo modelo?
          </Typography>
        </Box>
        <Box my="8px">
          <Divider />
        </Box>
        <Box>
          <Typography variant="body1" width="90%">
            a nota gerada substituirá o modelo de nota anterior, deseja
            continuar?
          </Typography>
        </Box>
        <Box mt="16px">
          <DialogActions>
            <Button
              fullWidth
              onClick={onClickCancel}
              variant="outlined"
              color="secondary"
              size="large"
            >
              cancelar
            </Button>
            <Button
              fullWidth
              onClick={onClickConfirm}
              variant="contained"
              size="large"
              color="secondary"
            >
              confirmar
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
