import * as Sentry from '@sentry/browser';
import axios, { AxiosError } from 'axios';

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

axiosInstance.interceptors.request.use((response) => {
  // TODO: we need to enable x-cloud-trace-context in the backend
  const cloudTraceContext = response.headers.get('X-Cloud-Trace-Context');
  if (cloudTraceContext) {
    Sentry.configureScope((scope) => {
      scope.setTag('transaction_id', response.headers['X-Cloud-Trace-Context']);
    });
  }
  return response;
});

export const addAuthHeader = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export type TLocalHTTPValidationError = AxiosError<{
  detail: [
    {
      input: string;
      loc: string[];
      msg: string;
      type: string;
    }
  ];
}>;
