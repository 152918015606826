import { useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { trackingEvent } from '@/app/helpers/events/events';
import { useTrackFuncWithDefaultRecordingProps } from '@/app/helpers/events/track-event';
import { useAppDispatch, useAppSelector } from '@/app/app-redux/store';
import { Alert, Box, TextField } from '@mui/material';
import { axiosInstance } from '@/app/helpers/services';

import { FullNoteModal } from './components/full-note-modal';
import { Tutorial } from '../player/components/tutorial';
import { Placement } from 'react-joyride';
import { openNote } from '@/app/modules/full-note/noteSlice';
import { addError } from '@/app/app-providers/error-handler-provider/errorHandlerSlice';

import { useIsMobile } from '@/app/helpers/hooks/useIsMobile';
import { useLocation } from 'react-router-dom';
import { addSideMenuNotification } from '@/app/modules/side-menu/sideMenuSlice';
import {
  DialogEnum,
  openDialog,
} from '@/app/modules/dialog-module/dialogSlice';

import { errors } from '@/app/helpers/errors';
import { notesQuery } from '../../screens/all-notes/all-notes';

export enum TEvaluation {
  Yes = 'yes',
  No = 'no',
}

type TServiceSaveNoteVariables = {
  id: string;
  notes: string;
};

type TServiceSaveNote = {
  transcription_id: string;
  consultation_id: string;
  id: string;
  notes: string;
  created_at: string;
};

export const FullNote = () => {
  const { trackFuncWithDefaultRecordingProps } =
    useTrackFuncWithDefaultRecordingProps();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    noteId: openedNoteId,
    isOpen,
    regenerated,
  } = useAppSelector((state) => state.note.openNote);

  const queryClient = useQueryClient();
  const { data: allNotes } = useQuery({
    ...notesQuery,
    enabled: true,
  });

  const notes = allNotes;

  const currentNote = (notes?.data || [])?.find(
    (note) => note.id === openedNoteId
  );

  const cachedNotes = currentNote?.notes || '';

  const [isEditing, setIsEditing] = useState(false);
  const [runTutorial, setRunTutorial] = useState(false);

  const saveNoteMutation = useMutation({
    mutationFn: ({ id, notes }: TServiceSaveNoteVariables) =>
      axiosInstance.patch<TServiceSaveNote>(`v2/medical_notes/${id}`, {
        notes,
      }),
    onSuccess: () => {
      setIsEditing(false);
    },
    onError: () => {
      dispatch(addError({ errors: [errors.generic] }));
    },
  });

  const handleOnClickCopy = () => {
    navigator.clipboard.writeText(cachedNotes);
    trackFuncWithDefaultRecordingProps(trackingEvent['Note Copied'], {
      note_id: currentNote?.id,
      complete: true,
    });
  };

  const handleOnFeedbackClick = (evaluation: TEvaluation) => {
    trackFuncWithDefaultRecordingProps(trackingEvent['Note Evaluated'], {
      note_id: currentNote?.id,
      evaluation,
    });
  };

  const handleOnClickEdit = () => {
    trackFuncWithDefaultRecordingProps(trackingEvent['Note Editing Started'], {
      note_id: currentNote?.id,
    });
    setIsEditing((current) => !current);
  };

  const handleOnClickSave = async () => {
    if (currentNote) {
      trackFuncWithDefaultRecordingProps(trackingEvent['Note Editing Ended'], {
        note_id: currentNote?.id,
      });
      saveNoteMutation.mutate({
        id: currentNote?.id,
        notes: cachedNotes,
      });
    }
  };

  const handleEditChange = (value: string) => {
    const updatedNotes = notes ? [...notes.data] : [];
    const updatedNoteIndex = updatedNotes.findIndex(
      (item) => item.id === currentNote?.id
    );

    if (updatedNotes.length > 0) {
      updatedNotes[updatedNoteIndex] = {
        ...updatedNotes[updatedNoteIndex],
        notes: value,
      };
    }

    if (currentNote && notes) {
      queryClient.setQueryData(notesQuery.queryKey, {
        ...notes,
        data: updatedNotes,
      });
    }
  };

  const tryToOpenContactDialog = () => {
    const AMOUNT_TO_SHOW_FEEDBACK_MODAL = 1;
    const dontShowFeedbackAgain = localStorage.getItem('dontShowFeedbackAgain');
    if (
      notes &&
      notes?.data?.length >= AMOUNT_TO_SHOW_FEEDBACK_MODAL &&
      dontShowFeedbackAgain !== 'true'
    ) {
      setTimeout(() => {
        dispatch(
          openDialog({
            dialogType: DialogEnum['request-contact'],
          })
        );
      }, 500);
    }
  };

  const handleOnClose = () => {
    dispatch(
      openNote({
        noteId: '',
        isOpen: false,
      })
    );

    // tryToOpenContactDialog();

    if (!location.pathname.includes('notes')) {
      const hasSeenNotesTutorial = localStorage.getItem('hasSeenNotesTutorial');
      if (!hasSeenNotesTutorial) {
        localStorage.setItem('hasSeenNotesTutorial', 'true');
        setRunTutorial(true);
      } else {
        dispatch(addSideMenuNotification('Notas anteriores'));
      }
    }
  };
  const isMobile = useIsMobile();

  return (
    <>
      <FullNoteModal
        key={currentNote?.id}
        isOpen={isOpen}
        handleClose={handleOnClose}
        onClickCopy={handleOnClickCopy}
        onClickFeedback={handleOnFeedbackClick}
        onClickEdit={handleOnClickEdit}
        onClickSave={handleOnClickSave}
        isEditing={isEditing}
        isSaving={saveNoteMutation.isPending}
        note={currentNote}
      >
        {Boolean(regenerated) && (
          <Box mb="16px">
            <Alert severity="warning">
              sua nota "{regenerated?.from}" foi alterada para o modelo "
              {regenerated?.to}" devido a gravação ser menor de 30 segundos
            </Alert>
          </Box>
        )}
        {isEditing ? (
          <TextField
            fullWidth
            multiline
            rows={isMobile ? 14 : 10}
            // defaultValue instead of value because of this https://github.com/mitodl/micromasters/issues/665
            defaultValue={cachedNotes}
            onChange={(e) => handleEditChange(e.target.value)}
            id="outlined-textarea"
            autoFocus
            focused={isEditing}
            onFocus={(e) => e.currentTarget.setSelectionRange(0, 0)}
            color="secondary"
            // sx={{
            //   '& fieldset': { border: '1px blue solid' },
            // }}
          />
        ) : (
          <Box
            minHeight={'308px'}
            dangerouslySetInnerHTML={{
              __html: cachedNotes,
            }}
          ></Box>
        )}
      </FullNoteModal>
      <Tutorial
        runTutorial={runTutorial}
        setRunTutorial={setRunTutorial}
        steps={[
          {
            target: '.side-menu-button',
            content: 'aqui você pode visualizar as suas notas anteriores',
            disableBeacon: true,
            placement: 'right' as Placement,
          },
        ]}
      />
    </>
  );
};
