import { useAppSelector } from '@/app/app-redux/store';

import { getAuthId } from '../services';

export const useIsTestingUser = () => {
  const testingUsers = useAppSelector((state) => state.auth.testingUsers);
  const currentUserId = getAuthId();

  if (testingUsers.find((user) => user.id === currentUserId)) {
    return true;
  }
  return false;
};
