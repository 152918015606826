import { TErrors } from '../app-providers/error-handler-provider/errorHandlerSlice';

type TErrorType = {
  [key: string]: TErrors;
};

export const errors: TErrorType = {
  'audio-001': {
    severity: 'warning',
    code: 'audio-001',
    title: 'Nota em reprocessamento',
    message: '',
  },
  'audio-002': {
    severity: 'error',
    code: 'audio-002',
    title: 'Falha ao alterar nota',
    message:
      'A nota não pôde ser alterada para este Tipo de registro porque possui menos de 30 segundos',
  },
  generic: {
    code: 'generic',
    title: 'Desculpe',
    message: 'Ocorreu um erro inesperado, tente novamente mais tarde',
    severity: 'error',
  },
  'account-creation-001': {
    code: 'account-creation-001',
    title: 'Desculpe',
    message:
      'O número de telefone é inválido. Por favor, verifique o número e tente novamente.',
    severity: 'error',
  },
};

export const FIREBASE_AUTH_ERROR_MESSAGES = {
  'auth/admin-restricted-operation':
    'Esta operação é restrita apenas a administradores.',
  'auth/argument-error':
    'Houve um erro nos argumentos fornecidos. Por favor, verifique os dados inseridos e tente novamente.',
  'auth/app-not-authorized':
    'Este aplicativo não está autorizado a usar a Autenticação Firebase. Por favor, verifique sua configuração e certifique-se de que está correta.',
  'auth/app-not-installed':
    'O aplicativo móvel solicitado não está instalado no seu dispositivo. Por favor, instale-o e tente novamente.',
  'auth/captcha-check-failed':
    'Falha na verificação do CAPTCHA. Por favor, tente a operação novamente.',
  'auth/code-expired': 'O código expirou. Por favor, solicite um novo.',
  'auth/cordova-not-ready':
    'O ambiente Cordova não está pronto. Certifique-se de que os plugins do Cordova estão devidamente instalados e tente novamente.',
  'auth/cors-unsupported':
    'CORS não é suportado pelo seu navegador. Verifique as configurações do navegador ou tente usar um navegador diferente.',
  'auth/credential-already-in-use':
    'Esta credencial já está associada a uma conta de usuário diferente.',
  'auth/custom-token-mismatch':
    'O token personalizado corresponde a um público diferente.',
  'auth/requires-recent-login':
    'Esta operação é sensível e requer uma autenticação recente. Faça login novamente antes de tentar esta solicitação.',
  'auth/dependent-sdk-initialized-before-auth':
    'Outro SDK foi inicializado antes da Autenticação Firebase. Certifique-se de que a Autenticação Firebase é inicializada primeiro.',
  'auth/dynamic-link-not-activated':
    'Links Dinâmicos não estão ativados. Ative-os no Console Firebase.',
  'auth/email-change-needs-verification':
    'A mudança de e-mail requer verificação. Verifique seu e-mail e siga o link para verificar seu novo endereço de e-mail.',
  'auth/email-already-in-use':
    'Usuário pode ou não existir na nossa família, tente de novo.',
  'auth/emulator-config-failed':
    'Falha na configuração do emulador. Verifique as configurações do emulador.',
  'auth/expired-action-code': 'O código de ação expirou. Solicite um novo.',
  'auth/cancelled-popup-request':
    'O popup foi fechado pelo usuário antes da finalização da operação.',
  'auth/internal-error': 'Ocorreu um erro interno. Tente novamente mais tarde.',
  'auth/invalid-api-key':
    'A chave da API é inválida. Verifique a chave e tente novamente.',
  'auth/invalid-app-credential':
    'A credencial do aplicativo é inválida. Verifique a credencial e tente novamente.',
  'auth/invalid-app-id':
    'O ID do aplicativo é inválido. Verifique o ID do aplicativo e tente novamente.',
  'auth/invalid-auth': 'O token de usuário é inválido. Faça login novamente.',
  'auth/invalid-auth-event':
    'O evento de autenticação é inválido. Tente a operação novamente.',
  'auth/invalid-cert-hash':
    'O hash do certificado é inválido. Verifique o certificado e tente novamente.',
  'auth/invalid-code':
    'O código de verificação é inválido. Solicite um novo código e tente novamente.',
  'auth/invalid-continue-uri':
    'A URI de continuação é inválida. Verifique a URI e tente novamente.',
  'auth/invalid-cordova-configuration':
    'A configuração do Cordova é inválida. Verifique a configuração e tente novamente.',
  'auth/invalid-custom-token':
    'O token personalizado é inválido. Verifique o token e tente novamente.',
  'auth/invalid-dynamic-link-domain':
    'O domínio do link dinâmico é inválido. Verifique o domínio e tente novamente.',
  'auth/invalid-email':
    'O endereço de e-mail é inválido. Verifique o e-mail e tente novamente.',
  'auth/invalid-emulator-scheme':
    'Ocorreu um erro interno. Tente novamente mais tarde.',
  'auth/invalid-idp-response':
    'A resposta do provedor de identidade é inválida.',
  'auth/invalid-login-credentials': 'As credenciais de login são inválidas.',
  'auth/invalid-message-payload': 'O payload da mensagem é inválido.',
  'auth/invalid-mfa-session': 'A sessão de autenticação multifator é inválida.',
  'auth/invalid-oauth-client-id': 'O ID do cliente OAuth é inválido.',
  'auth/invalid-oauth-provider': 'O provedor OAuth é inválido.',
  'auth/invalid-oob-code': 'O código de operação é inválido.',
  'auth/invalid-origin': 'A origem da solicitação não está autorizada.',
  'auth/invalid-password': 'A senha é inválida ou muito fraca.',
  'auth/invalid-persistence': 'O tipo de persistência é inválido.',
  'auth/invalid-phone-number': 'O número de telefone é inválido.',
  'auth/invalid-provider-id': 'O ID do provedor é inválido.',
  'auth/invalid-recipient-email': 'O e-mail do destinatário é inválido.',
  'auth/invalid-sender': 'A informação do remetente é inválida.',
  'auth/invalid-session-info': 'As informações da sessão são inválidas.',
  'auth/invalid-tenant-id': 'O ID do tenant é inválido.',
  'auth/mfa-info-not-found':
    'As informações de autenticação multifator não foram encontradas.',
  'auth/mfa-required': 'É necessária a autenticação multifator.',
  'auth/missing-android-package-name': 'Falta o nome do pacote Android.',
  'auth/missing-app-credential': 'Faltam as credenciais do aplicativo.',
  'auth/missing-auth-domain':
    'Falta o domínio de autenticação na configuração.',
  'auth/missing-code': 'Falta o código de verificação.',
  'auth/missing-continue-uri': 'Falta a URI de continuação.',
  'auth/missing-iframe-start': 'Falta o início do iFrame.',
  'auth/missing-ios-bundle-id': 'Falta o ID do pacote iOS.',
  'auth/missing-or-invalid-nonce': 'O nonce está faltando ou é inválido.',
  'auth/missing-mfa-info': 'Faltam informações de autenticação multifator.',
  'auth/missing-mfa-session': 'Falta a sessão de autenticação multifator.',
  'auth/missing-phone-number': 'Falta o número de telefone.',
  'auth/missing-session-info': 'Faltam informações da sessão.',
  'auth/module-destroyed': 'O módulo foi destruído.',
  'auth/need-confirmation': 'É necessária uma confirmação.',
  'auth/network-request-failed': 'A solicitação de rede falhou.',
  'auth/null-user': 'O usuário é nulo.',
  'auth/no-auth-event': 'Nenhum evento de autenticação.',
  'auth/no-such-provider': 'O provedor especificado não existe.',
  'auth/operation-not-allowed': 'A operação não é permitida.',
  'auth/operation-not-supported': 'A operação não é suportada neste ambiente.',
  'auth/popup-blocked': 'O popup foi bloqueado.',
  'auth/popup-closed-by-user': 'O popup foi fechado pelo usuário.',
  'auth/provider-already-linked': 'O provedor já está vinculado.',
  'auth/quota-exceeded': 'A cota foi excedida.',
  'auth/redirect-cancelled-by-user':
    'O redirecionamento foi cancelado pelo usuário.',
  'auth/redirect-operation-pending':
    'Uma operação de redirecionamento está pendente.',
  'auth/rejected-credential': 'A credencial foi rejeitada.',
  'auth/second-factor-already-enrolled': 'O segundo fator já está inscrito.',
  'auth/second-factor-limit-exceeded':
    'O limite de segundos fatores foi excedido.',
  'auth/tenant-id-mismatch': 'Incompatibilidade do ID do tenant.',
  'auth/timeout': 'O tempo de operação expirou.',
  'auth/token-expired': 'O token expirou.',
  'auth/too-many-attempts-try-later':
    'Muitas tentativas. Tente novamente mais tarde.',
  'auth/unauthorized-domain': 'Domínio não autorizado.',
  'auth/unsupported-first-factor': 'O primeiro fator não é suportado.',
  'auth/unsupported-persistence': 'O tipo de persistência não é suportado.',
  'auth/unsupported-tenant-operation': 'Operação de tenant não suportada.',
  'auth/unverified-email': 'O e-mail não foi verificado.',
  'auth/user-cancelled': 'O usuário cancelou a operação.',
  'auth/user-deleted': 'O usuário foi excluído.',
  'auth/user-disabled': 'O usuário está desativado.',
  'auth/user-mismatch': 'Incompatibilidade de usuário.',
  'auth/user-signed-out': 'O usuário fez logout.',
  'auth/weak-password': 'A senha é muito fraca.',
  'auth/web-storage-unsupported': 'Armazenamento web não suportado.',
  'auth/already-initialized': 'A autenticação já foi inicializada.',
  'auth/recaptcha-not-enabled': 'O reCAPTCHA não está habilitado.',
  'auth/missing-recaptcha-token': 'Falta o token do reCAPTCHA.',
  'auth/invalid-recaptcha-token': 'O token do reCAPTCHA é inválido.',
  'auth/invalid-recaptcha-action': 'A ação do reCAPTCHA é inválida.',
  'auth/missing-client-type': 'Falta o tipo de cliente.',
  'auth/missing-recaptcha-version': 'Falta a versão do reCAPTCHA.',
  'auth/invalid-recaptcha-version': 'A versão do reCAPTCHA é inválida.',
  'auth/invalid-req-type': 'O tipo de solicitação é inválido.',
  'auth/invalid-credential':
    'Usuário e senha não combinam ou podem não existir, tente de novo ou resete a senha',
};
