import { getAnalytics, logEvent } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';

import { store } from '@/app/app-redux/store';

export const trackEvent = (
  event: string,
  eventParams?: Record<string, unknown>
) => {
  try {
    const analytics = getAnalytics();
    logEvent(analytics, event, eventParams);
  } catch (error) {
    console.log('Log Firebase Event Error');
  }
  amplitude.track(event, eventParams);
};

export const useTrackFuncWithDefaultRecordingProps = () => {
  return {
    trackFuncWithDefaultRecordingProps: (
      event: string,
      eventParams?: Record<string, unknown>
    ) => {
      const state = store.getState();
      const defaultRecordingProps = {
        recording_id: state.recording.recordingId || '',
        specialty: state.template.currentTemplate || '',
        mode: state.template.currentConsultationMode || '',
      };

      trackEvent(event, {
        ...defaultRecordingProps,
        ...eventParams,
      });
    },
  };
};
